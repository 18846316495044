import type { CommonAnswersType } from "../gameplay-answer";
import "./gameplay-image.scss";
import cx from "classnames";

export type GameplayAnswerImageProps = CommonAnswersType & {
  gameplay: "image";
  options: {
    imageSrc: string;
  };
};

// type GameplayType = { gameplay: "image" };

// export type Extracted = Extract<GameplayAnswerProps, GameplayType>;

// export type GameplayAnswerImageProps = GameplayType &
//   Extracted["options"] &
//   Omit<Extracted, "options">;

export function GameplayAnswerImage({
  options: { imageSrc },
  status = "default",
  ...props
}: GameplayAnswerImageProps) {
  return (
    <button
      {...props}
      className={cx("gameplay-image", props.className, {
        correct: status === "correct",
        wrong: status === "wrong",
      })}
    >
      {status !== "default" ? (
        <img
          className="answer-badge"
          src={`../img/icns/answer-${status}.png`}
          height={54}
          width={54}
          alt={status}
        />
      ) : null}
      <div className="image-area">
        <img src={imageSrc} />
      </div>
    </button>
  );
}

export default GameplayAnswerImage;
