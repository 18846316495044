// import { motion, Variants } from "framer-motion";
import { useAppSelector } from "../../../hooks/hooks";
import {
  selectInit,
  // selectLanguage,
  // selectTranslations,
} from "../../data-access/store/general/generalSlice";
import "./upstream-service-banner.scss";
// import { checkTranslationKey } from "../../../utils/translation";

// const bannerVariants: Variants = {
//   hidden: {
//     y: "100%",
//   },
//   visible: {
//     y: 0,
//     transition: {
//       duration: 0.3,
//       ease: "easeOut",
//     },
//   },
// };

export function UpstreamServiceBanner() {
  // const lang = useAppSelector(selectLanguage);
  // const translations = useAppSelector(selectTranslations);
  const init = useAppSelector(selectInit);

  if (!init?.features.withBranding) {
    return <></>;
  }

  return (
    <div
      id="banner-upstream"
      // variants={bannerVariants}
      // initial="hidden"
      // animate="visible"
    >
      <div className="banner-area">
        <div className="flex-container">
          <div className="service-text">
            Connected by
            <img src="/img/vodacom-logo.png" alt="" />
            Vodacom
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpstreamServiceBanner;
