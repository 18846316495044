import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CoinOfferButton } from "../../../shared/ui/coin-offer";
import { Modal } from "../../../shared/ui/modal";
import { StoreBuyCoins } from "../../../shared/ui/modal-notifications/store-buy-coins";
import "./store-coins.scss";
import {
  useBuyCreditPackageMutation,
  useGetCreditPackagesQuery,
} from "../../data-access/store/services/store.service";
import { CreditPackagesResponse } from "../../../shared/interfaces/general.types";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import {
  selectLanguage,
  selectTranslations,
} from "../../../shared/data-access/store/general/generalSlice";
import { AnimatePresence, motion, Variants } from "framer-motion";
import { setMessage } from "../../../shared/data-access/store/message/messageSlice";
import { useLazyGetUserQuery } from "../../../auth/data-access/store/services/auth.service";
import { checkTranslationKey } from "../../../utils/translation";
import { selectUser } from "../../../auth/data-access/store/authSlice";

const storeContainerVariants: Variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.3,
    },
  },
};

const storeIconVariants: Variants = {
  hidden: {
    opacity: 0,
    scale: 0,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.3,
      delay: 0.3,
    },
  },
};

const creditPackageVariants: Variants = {
  hidden: {
    opacity: 0,
    y: -20,
  },
  visible: (custom) => ({
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.2,
      delay: 0.3 + 0.2 * (custom + 1),
    },
  }),
};

type CoinsPageProps = {
  title?: string;
  subtitle?: string;
  confirmCallback?: () => void;
};

export function CoinsPage({
  title,
  subtitle,
  confirmCallback,
}: CoinsPageProps) {
  const dispatch = useAppDispatch();
  const [getUser] = useLazyGetUserQuery();
  const [showModal, setShowModal] = useState(false);
  const { data: creditPackagesData } = useGetCreditPackagesQuery();
  const lang = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);
  const [selectedPackageIndex, setSelectedPackageIndex] = useState(0);
  const [
    buyCreditPackage,
    {
      error: buyCreditPackageError,
      isSuccess: buyCreditPackageIsSuccess,
      isLoading: buyCreditPackageIsLoading,
    },
  ] = useBuyCreditPackageMutation();
  const user = useAppSelector(selectUser);
  const navigate = useNavigate();

  const templateColors: Array<"orange" | "purple" | "blue"> = [
    "orange",
    "purple",
    "blue",
  ];

  useEffect(() => {
    if (buyCreditPackageError) {
      const errorMsg =
        (buyCreditPackageError as { data?: Error })?.data?.message ||
        checkTranslationKey(translations[lang]?.Error, "Error!");
      if (errorMsg) {
        dispatch(setMessage({ content: errorMsg, type: "error" }));
        setShowModal(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyCreditPackageError]);

  useEffect(() => {
    if (buyCreditPackageIsSuccess) {
      setShowModal(false);
      getUser();

      const boughtQty =
        creditPackagesData?.items[selectedPackageIndex]?.quantity || 0;
      dispatch(
        setMessage({
          content: `Your request for ${boughtQty} ${
            boughtQty > 1 ? "coins" : "coin"
          } is being processed`,
          type: "success",
        })
      );

      if (confirmCallback) {
        confirmCallback();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyCreditPackageIsSuccess, dispatch, getUser]);

  const handleBuyCreditPackage = () => {
    buyCreditPackage({
      packageId: creditPackagesData?.items[selectedPackageIndex].id || "",
    });
  };

  const renderCreditPackages = (
    creditPackages: CreditPackagesResponse["items"]
  ) => {
    return creditPackages?.map((creditPackage, index) => {
      return (
        <motion.div
          key={creditPackage.id}
          variants={creditPackageVariants}
          initial="hidden"
          animate="visible"
          custom={index}
          style={{ width: "100%" }}
        >
          <CoinOfferButton
            className="store-offer"
            offerType={templateColors[index]}
            price={creditPackage.currency + creditPackage.price}
            coins={creditPackage.quantity}
            happyHour={creditPackage.happy_hour_offer}
            onClick={() => {
              if (user?.eligibility.authentication === "automatic") {
                navigate("/auth/sign-in", {
                  replace: true,
                  state: "redirectedFromStore",
                });
              } else {
                setSelectedPackageIndex(index);
                setShowModal(true);
              }
            }}
            heading={
              creditPackage.heading
                ? creditPackage.heading?.text[lang]
                : undefined
            }
          ></CoinOfferButton>
        </motion.div>
      );
    });
  };

  return (
    <>
      <AnimatePresence>
        {creditPackagesData && (
          <motion.div
            className="card-container"
            variants={storeContainerVariants}
            initial="hidden"
            animate="visible"
          >
            <div className="icon-position">
              <motion.img
                src="../img/icns/shop-coin.png"
                alt=""
                width={"50px"}
                variants={storeIconVariants}
                initial="hidden"
                animate="visible"
              />
            </div>
            <div className="card-content">
              <div className="text">
                <h3>
                  {title ||
                    checkTranslationKey(
                      translations[lang]?.Buy_more_coins_complete_more,
                      "Buy more coins, complete more challenges and get a head start in the leaderboard."
                    )}
                </h3>
                {subtitle && <p>{subtitle}</p>}
              </div>
              <div className="store-offers">
                {creditPackagesData && creditPackagesData.items
                  ? renderCreditPackages(creditPackagesData.items)
                  : null}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <Modal show={showModal} setShow={setShowModal}>
        <StoreBuyCoins
          onButton1Click={() => setShowModal(false)}
          onButton2Click={handleBuyCreditPackage}
          button2Disabled={buyCreditPackageIsLoading}
          numberCoins={
            creditPackagesData?.items[selectedPackageIndex]?.quantity || 0
          }
          priceCoins={
            (creditPackagesData?.items[selectedPackageIndex]?.currency || "") +
            (creditPackagesData?.items[selectedPackageIndex]?.price || "")
          }
        ></StoreBuyCoins>
      </Modal>
    </>
  );
}

export default CoinsPage;
