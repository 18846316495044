import { Icons } from "../../interfaces/icons.type";
import { Button, ButtonProps } from "../button";
import "./ladder-button.scss";
// import { AnimatePresence, motion, Variants } from "framer-motion";
import { motion } from "framer-motion";
import { useAppSelector } from "../../../hooks/hooks";
import {
  selectLanguage,
  selectTranslations,
} from "../../data-access/store/general/generalSlice";
import { checkTranslationKey } from "../../../utils/translation";

export interface LadderButtonProps extends ButtonProps {
  refCallback: (el: HTMLElement | null) => void;
  active?: boolean;
  readyToPlay?: boolean;
  count: number;
  icon: Icons;
  endIcon?: Icons;
  prize?: string;
  displayOponents?: boolean;
  buttonType?: ButtonProps["buttonType"];
  disabled?: boolean;
  animationSettings?: {
    delay?: number;
  };
  animation?: boolean;
  handlePlay: () => void;
}

export function LadderButton({
  refCallback,
  count,
  icon,
  endIcon,
  prize,
  active,
  readyToPlay,
  animationSettings,
  buttonType,
  handlePlay,
  disabled,
  animation,
  ...props
}: LadderButtonProps) {
  const lang = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);

  // const variantsSelectButton: Variants = {
  //   hidden: {
  //     height: 0,
  //     opacity: 0,
  //     transition: {
  //       duration: 0.3,
  //       delay:
  //         animationSettings && animationSettings.delay
  //           ? animationSettings.delay
  //           : 0,
  //     },
  //   },
  //   visible: {
  //     height: 31,
  //     opacity: 1,
  //     transition: {
  //       duration: 0.3,
  //       delay:
  //         animationSettings && animationSettings.delay
  //           ? animationSettings.delay
  //           : 0,
  //     },
  //   },
  // };

  const variantsButtonText = {
    normal: {
      // color: "#000",
      // textShadow: "none",
    },
    pending: {
      // color: "#000",
    },
    gold: {
      // fontWeight: 900,
      // fontSize: "32px",
      // lineHeight: "27px",
      // color: "#FFFFFF",
      // transition: {
      //   duration: 0,
      // },
    },
    "success-gold": {
      // fontWeight: 900,
      // fontSize: "32px",
      // lineHeight: "27px",
      // color: "#FFFFFF",
      // transition: {
      //   duration: 0,
      // },
    },
    "success-play": {
      // fontWeight: 900,
      // fontSize: "32px",
      // lineHeight: "27px",
      // color: "#FFFFFF",
      // transition: {
      //   duration: 0,
      // },
    },
    green: {
      // color: "#FFFFFF",
      // transition: {
      //   duration: 0.3,
      //   delay:
      //     animationSettings && animationSettings.delay
      //       ? animationSettings.delay
      //       : 0,
      // },
    },
    disabled: {
      // color: "#000",
    },
  };

  return (
    <>
      <div className="ladder-button-container">
        <Button
          {...props}
          animation={animation}
          animationSettings={animationSettings}
          disabled={disabled}
          buttonType={buttonType}
          className={`ladder-button ${props.className ?? ""}`}
          refCallback={refCallback}
        >
          <span className="count-start">
            <span className="icon-position">
              {icon ? <img src={`/img/icns/${icon}.png`} alt="" /> : null}
            </span>
            <motion.span
              className="count"
              initial={false}
              variants={variantsButtonText}
              animate={[
                animation && disabled ? "disabled" : "",
                animation && buttonType === "ladder-white" ? "normal" : "",
                animation &&
                (buttonType === "green" ||
                  buttonType === "success-gold" ||
                  buttonType === "success-play")
                  ? "green"
                  : "",
                animation && buttonType === "ladder-yellow" ? "pending" : "",
                animation && buttonType === "gold" ? "gold" : "",
                animation && buttonType === "success-gold"
                  ? "success-gold"
                  : "",
                animation && buttonType === "success-play"
                  ? "success-play"
                  : "",
              ]}
            >
              {count}
            </motion.span>
          </span>
          <span className="count-end">
            {prize ? <img src={prize} alt="" className="prize" /> : null}
            {endIcon ? (
              <img
                src={`/img/icns/${endIcon}.png`}
                alt=""
                className={"end-icon"}
              />
            ) : null}
          </span>
        </Button>
        {/*{displayOponents ? (*/}
        {/*  <div className="slot-avatar slot-end-avatar">*/}
        {/*    <Avatar*/}
        {/*      image={*/}
        {/*        "https://images.unsplash.com/photo-1564564321837-a57b7070ac4f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1176&q=80"*/}
        {/*      }*/}
        {/*      right={*/}
        {/*        <Badge type={winner ? "lost" : "win"} aspect="circle">*/}
        {/*          <img*/}
        {/*            src={`../img/icns/${winner ? "cross" : "verified"}.png`}*/}
        {/*          />*/}
        {/*        </Badge>*/}
        {/*      }*/}
        {/*    ></Avatar>*/}
        {/*  </div>*/}
        {/*) : undefined}*/}
        <div
          className={`selector-label ${active && readyToPlay ? "active" : ""}`}
          onClick={handlePlay}
        >
          <h4>
            {checkTranslationKey(
              translations[lang]?.Select_to_play,
              "Select to play"
            )}
          </h4>
        </div>
        {/*<AnimatePresence>*/}
        {/*  {active && readyToPlay ? (*/}
        {/*    <motion.div*/}
        {/*      className="selector-label"*/}
        {/*      initial={"hidden"}*/}
        {/*      variants={variantsSelectButton}*/}
        {/*      animate={buttonType !== "ladder-yellow" && "visible"}*/}
        {/*      exit={"hidden"}*/}
        {/*      onClick={handlePlay}*/}
        {/*    >*/}
        {/*      <h4>*/}
        {/*        {checkTranslationKey(*/}
        {/*          translations[lang]?.Select_to_play,*/}
        {/*          "Select to play"*/}
        {/*        )}*/}
        {/*      </h4>*/}
        {/*    </motion.div>*/}
        {/*  ) : null}*/}
        {/*</AnimatePresence>*/}
      </div>
    </>
  );
}
