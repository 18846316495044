import { Formik, Form } from "formik";
import { Button } from "../../../shared/ui/button";
import "./profile-language.scss";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import {
  selectInit,
  selectLanguage,
  selectTranslations,
  updateLanguage,
} from "../../../shared/data-access/store/general/generalSlice";
import { checkTranslationKey } from "../../../utils/translation";

type ChooseLanguageProps = {
  closeModal: () => void;
};

export function ChooseLanguage({ closeModal }: ChooseLanguageProps) {
  const dispatch = useAppDispatch();
  const init = useAppSelector(selectInit);
  const lang = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);

  return (
    <div className="language-container">
      <Formik
        initialValues={{
          picked: lang,
        }}
        onSubmit={async (values) => {
          if (values.picked) {
            localStorage.setItem("lang", values.picked);
            dispatch(updateLanguage({ language: values.picked }));
            closeModal();
          }
        }}
      >
        {({ values, handleChange }) => {
          const languages = init?.i18n?.languages;

          return (
            <Form className="form-container">
              <div className="form-content">
                <div className="title-language">
                  {checkTranslationKey(
                    translations[lang]?.Choose_Language,
                    "Choose Language"
                  )}
                </div>

                <div role="group" className="language-grid">
                  {languages
                    ? Object.keys(languages)?.map((language) => {
                        if (!languages[language].enabled) {
                          return null;
                        }
                        return (
                          <div className="input-aspect" key={language}>
                            <div className="language">
                              <div className="flag">
                                <img
                                  src={languages[language].icon}
                                  height={50}
                                  alt={languages[language].name}
                                />
                              </div>

                              <label htmlFor={language}>
                                {languages[language].name}
                              </label>
                            </div>
                            <div className="check-container">
                              <input
                                checked={values.picked === language}
                                type="radio"
                                name="picked"
                                id={language}
                                value={language}
                                className="radio-custom-label"
                                onChange={handleChange}
                              />
                              <span className="checked-img"></span>
                            </div>
                          </div>
                        );
                      })
                    : null}
                </div>
              </div>
              <div className="button-submit-position">
                <Button buttonType="success">
                  {checkTranslationKey(translations[lang]?.OK, "OK")}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default ChooseLanguage;
