import { ComponentProps } from "react";
import { Button } from "../../button";
import "./store-buy-coins.scss";
import { useAppSelector } from "../../../../hooks/hooks";
import {
  selectLanguage,
  selectTranslations,
} from "../../../data-access/store/general/generalSlice";
import { checkTranslationKey } from "../../../../utils/translation";

export interface StoreBuyCoinsProps extends ComponentProps<"div"> {
  numberCoins: number;
  priceCoins: string;
  onButton1Click: () => void;
  onButton2Click: () => void;
  button2Disabled?: boolean;
}

export function StoreBuyCoins({
  onButton1Click,
  onButton2Click,
  numberCoins,
  priceCoins,
  button2Disabled,
  ...props
}: StoreBuyCoinsProps) {
  const lang = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);

  return (
    <div className="modal-content" {...props}>
      <div className="top-area">
        <div className="message-icon">
          <img
            src="../img/icns/shop-coin.png"
            alt={checkTranslationKey(
              translations[lang]?.Shop_coins_icon,
              "Shop coins icon"
            )}
            width="60em"
          />
        </div>
        <div className="message">
          <div className="text text-big">
            {checkTranslationKey(
              translations[lang]?.Are_you_sure_to_buy,
              "Are you sure to buy"
            )}{" "}
            {numberCoins}{" "}
            {numberCoins > 1
              ? checkTranslationKey(translations[lang]?.coins, "coins")
              : checkTranslationKey(translations[lang]?.coin, "coin")}{" "}
            {checkTranslationKey(translations[lang]?.for, "for")} {priceCoins}?
          </div>
        </div>
      </div>
      <div className="bottom-area">
        <Button buttonType="white" onClick={onButton1Click}>
          {checkTranslationKey(translations[lang]?.No, "No")}
        </Button>
        <Button
          buttonType="success"
          onClick={onButton2Click}
          disabled={button2Disabled}
        >
          {checkTranslationKey(translations[lang]?.Yes, "Yes")}
        </Button>
      </div>
    </div>
  );
}

export default StoreBuyCoins;
