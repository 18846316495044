import { Navigate, Route, Routes } from "react-router-dom";
import { PrizesView } from "../prizes-view";

export function PrizesShell() {
  return (
    <Routes>
      <Route path="/" element={<PrizesView />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
}

export default PrizesShell;
