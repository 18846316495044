import { Navigate, Route, Routes } from "react-router-dom";
import { LeaderboardView } from "../leaderboard-view";

export function LeaderboardShell() {
  return (
    <Routes>
      <Route path="/" element={<LeaderboardView />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
}

export default LeaderboardShell;
