import { Navigate, Route, Routes } from "react-router-dom";
import { Opponent } from "../opponent/opponent";

export function OpponentShell() {
  return (
    <Routes>
      <Route path="/" element={<Opponent />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
}

export default OpponentShell;
