import React from "react";
import { Button } from "../../../shared/ui/button";

import "./modal-extra-coin.scss";

interface ModalExtraCoinProps {
  button: {
    text: string;
    action?: () => void;
  };
}

const ModalExtraCoin = ({ button }: ModalExtraCoinProps) => {
  return (
    <div className={"modal-extra-coin"}>
      <h3 className="title">Congratulations</h3>
      <p>You now have 1 extra coin!</p>
      <img src="/img/icns/coin.svg" alt="" className={"coin"} />
      <Button
        buttonType="success"
        type="submit"
        value="Submit"
        onClick={button.action}
      >
        {button.text}
      </Button>
    </div>
  );
};

export default ModalExtraCoin;
