import { Navigate, Route, Routes } from "react-router-dom";
import { CategoryView } from "../category-view";

export function CategoryShell() {
  return (
    <Routes>
      <Route path="/" element={<CategoryView />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default CategoryShell;
