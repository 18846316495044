import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { SignIn } from "../sign-in";
import { SignUp } from "../sign-up";
import { ForgotPassword } from "../forgot-password";
import { Unsubscribe } from "../unsubscribe";
import { Subscribe } from "../subscribe";
import { Pin } from "../pin";

import PrivateRoute from "../../../shared/guards/PrivateRoute";
import SubscribeGuest from "../subscribe-guest/subscribe-guest";

export function AuthShell() {
  const prevRoute = useLocation();

  return (
    <Routes>
      <Route
        element={
          <PrivateRoute
            grantedRoles={["guest"]}
            onDenied={() => <Navigate to="/" replace />}
            onIncomplete={false}
          />
        }
      >
        <Route path="sign-up" element={<SignUp />} />
        <Route path="pin" element={<Pin />} />
      </Route>
      <Route
        element={
          <PrivateRoute
            grantedRoles={["guest", "userToken"]}
            onDenied={() => {
              return prevRoute.state === "redirectedFromStore" ? (
                <Navigate to="/store" state="coins" replace />
              ) : prevRoute.state === "redirectedFromProfile" ? (
                <Navigate to="/auth/unsubscribe" replace />
              ) : (
                <Navigate to="/" replace />
              );
            }}
            onIncomplete={() => <Navigate to="/auth/sign-up" replace />}
          />
        }
      >
        <Route index element={<SignIn />} />
        <Route path="sign-in" element={<SignIn />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
      </Route>
      <Route
        element={
          <PrivateRoute
            grantedRoles={["user", "userToken"]}
            onDenied={() => <Navigate to="/auth/sign-in" replace />}
            onIncomplete={() => <Navigate to="/auth/sign-up" replace />}
          />
        }
      >
        <Route path="unsubscribe" element={<Unsubscribe />} />
      </Route>
      <Route path="subscribe" element={<Subscribe />} />
      <Route path="subscribe-guest" element={<SubscribeGuest />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
}

export default AuthShell;
