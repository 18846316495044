import { useEffect } from "react";
import { AnimatePresence, motion, Variants } from "framer-motion";

import SoundManager from "../../../../utils/managers/sound-manager";
import { useAppSelector } from "../../../../hooks/hooks";
import {
  selectLanguage,
  selectTranslations,
} from "../../../data-access/store/general/generalSlice";
import { checkTranslationKey } from "../../../../utils/translation";
import { fadeOutBgAudio } from "../../../../utils/fade-audio";
import { nextLevel } from "../../../../utils/avatarBorder";

import { Avatar } from "../../avatar";
import { Modal } from "../../modal";
import { Button } from "../../button";

import "./border-unlocked.scss";

const levelUpContainerVariants: Variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.3,
    },
  },
};

const contentVariants: Variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.3,
      delay: 1.5,
    },
  },
};

const bgVariants: Variants = {
  hidden: {
    opacity: 0,
    scale: 0,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.3,
      delay: 0.3,
      scale: {
        type: "spring",
        bounce: 0.7,
      },
    },
  },
};

const rayVariants: Variants = {
  hidden: {
    opacity: 0,
    scale: 0,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.3,
      delay: 0.5,
    },
  },
};

const badgeVariants: Variants = {
  hidden: {
    opacity: 0,
    scale: 0,
  },
  visible: {
    opacity: 1,
    scale: [1, 1.1, 1],
    transition: {
      duration: 0.2,
      delay: 0.8,
      scale: {
        repeat: 1,
      },
    },
  },
};

export interface BorderUnlockedProps {
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  buttonClick: () => void;
  level: number;
  title: string;
  subtitle: string;
  buttonText: string;
  avatar: string;
}

export function BorderUnlocked({
  modalOpen,
  setModalOpen,
  buttonClick,
  level,
  title,
  subtitle,
  buttonText,
  avatar,
}: BorderUnlockedProps) {
  const lang = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);
  const nextLevelBorder = nextLevel(level);

  useEffect(() => {
    if (modalOpen) {
      fadeOutBgAudio(
        SoundManager.ambientSounds.home,
        SoundManager.vfxSounds.level_up
      );
    }
  }, [modalOpen]);

  return (
    <AnimatePresence>
      {modalOpen && (
        <motion.div
          id="border-unlocked"
          className={modalOpen ? "modal-open" : ""}
          variants={levelUpContainerVariants}
          initial={"hidden"}
          animate={"visible"}
          exit={"hidden"}
        >
          <Modal
            setShow={setModalOpen}
            show={modalOpen}
            defaultAnimations={false}
            hideCloseButton
            hasLights
            disableClickOutside
          >
            <>
              <motion.img
                src="../img/level-up/bg-modal.png"
                alt=""
                className="bg-modal"
                variants={bgVariants}
                initial={"hidden"}
                animate={modalOpen && "visible"}
              />
              <motion.img
                src="../img/level-up/rays-modal-top.svg"
                alt=""
                className="rays rays-top"
                variants={rayVariants}
                initial={"hidden"}
                animate={modalOpen && "visible"}
              />
              <motion.img
                src="../img/level-up/rays-modal-bottom.svg"
                alt=""
                className="rays rays-bottom"
                variants={rayVariants}
                initial={"hidden"}
                animate={modalOpen && "visible"}
              />
              <motion.div
                className="level"
                variants={badgeVariants}
                initial={"hidden"}
                animate={modalOpen && "visible"}
              >
                <Avatar image={avatar} className="user-image" level={level} />
                <img src="/img/icns/ribon-simple.svg" alt="" />
              </motion.div>
              <motion.div
                className="content"
                variants={contentVariants}
                initial={"hidden"}
                animate={"visible"}
              >
                <p className="title-modal">{title}</p>
                <p className="subtitle-modal">{subtitle}</p>
                {nextLevelBorder ? (
                  <>
                    <p className="description">
                      {checkTranslationKey(translations[lang]?.Reach, "Reach")}{" "}
                      <strong>
                        {" "}
                        {checkTranslationKey(
                          translations[lang]?.level,
                          "level"
                        )}{" "}
                        {nextLevelBorder}
                      </strong>{" "}
                      <br />
                      {checkTranslationKey(
                        translations[lang]?.to_unlock_next,
                        "to unlock next"
                      )}
                    </p>
                    <img
                      className="next-level-border"
                      src={`/img/avatars-borders/AvatarBorder-lv${nextLevelBorder}.svg`}
                      alt=""
                    />
                  </>
                ) : (
                  ""
                )}

                <div className="btn-container">
                  <Button
                    buttonType="success"
                    type="submit"
                    value="Submit"
                    onClick={() => {
                      setModalOpen(false);
                      buttonClick();
                    }}
                  >
                    {buttonText}
                  </Button>
                </div>
              </motion.div>
            </>
          </Modal>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
