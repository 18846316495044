import {
  IndexedQuestion,
  Question,
} from "../../shared/data-access/store/game/types/socket-events/start-round";
import shuffle from "lodash/shuffle";

const shuffleAnswers = (question: Question): IndexedQuestion => {
  const shuffledIndexedQuestion = {
    ...question,
    answers: shuffle(
      question.answers.map((answer, index) => ({ ...answer, index }))
    ),
  };

  return shuffledIndexedQuestion;
};

export default shuffleAnswers;
