import { SocketEvents } from "./socket-events";

export type SocketEventNames = SocketEvents["call"] | "error_code";
export type SocketResponseError = {
  call: SocketEventNames;
  error_header: number;
  error: {
    error: string;
  };
};

export type SocketEvent<T extends SocketEventNames> = Extract<
  SocketEvents,
  { call: T }
>;

export type SocketRequestParams<
  T extends SocketEventNames,
  _Event extends SocketEvents = SocketEvent<T>
> = _Event["request"];

export type SocketRequest<T extends SocketEventNames> = {
  call: T;
  params: SocketRequestParams<T>;
};

export type SocketResponse<
  T extends SocketEventNames,
  _Event extends SocketEvents = SocketEvent<T>
> = {
  call: T;
  data: _Event["response"];
};

export type SocketRequests = SocketRequest<SocketEventNames>;
export type SocketResponses = SocketResponse<SocketEventNames>;

/**
 * This function asserts both in typescript and
 * on runtime that the data is of the type requested.
 * @param eventName eventName passed in the call property
 * @param data data received
 * @returns boolean

 * @example
 * // returns true if d is of type "init_game"
 * socketTypeAssert<SocketResponse<"init_game">>("init_game", d)
 */
export const socketTypeAssert = <T extends SocketRequests | SocketResponses>(
  eventName: SocketEventNames,
  data: SocketRequests | SocketResponses
): data is T => data.call === eventName;

export const socketTypeError = (
  data: SocketResponses | SocketResponseError
): data is SocketResponseError => "error_header" in data && "error" in data;
