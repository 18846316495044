import { ComponentProps } from "react";
import { Button } from "../../button";
import "./sms-sent.scss";

export interface SmsSentProps extends ComponentProps<"div"> {
  phone: string;
  onButtonClick: () => void;
  buttonText: string;
  instructions: string;
}

export function SmsSent({
  phone,
  onButtonClick,
  buttonText,
  instructions,
  ...props
}: SmsSentProps) {
  return (
    <div className="modal-content" {...props}>
      <div className="top-area">
        <div className="message-icon">
          <img src="../img/icns/message.png" alt="" width="60em" />
        </div>
        <div className="message">
          <div className="text">{instructions}</div>
          <div className="phone">{phone}</div>
        </div>
      </div>
      <div className="bottom-area">
        <Button onClick={onButtonClick} buttonType="success">
          {buttonText}
        </Button>
      </div>
    </div>
  );
}

export default SmsSent;
