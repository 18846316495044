import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { logout } from "../../auth/data-access/store/authSlice";
import {
  setIsLoadingPage,
  setModal,
} from "../../shared/data-access/store/ui/uiSlice";
import { Background } from "../../shared/ui/background";
import ButtonClose from "../../shared/ui/button-close/button-close";
import { checkTranslationKey } from "../../utils/translation";
import "./menu.scss";
import {
  selectInit,
  selectLanguage,
  selectTranslations,
} from "../../shared/data-access/store/general/generalSlice";
import { gameRoutes } from "../../App";
import useLocals from "../../utils/hooks/use-locals";
import { useGameEvents } from "../../shared/data-access/store/game/hooks/use-game-events";
import { useEffect, useMemo } from "react";
import { reset } from "../../shared/data-access/store/game/gameSlices";
import { fadeOutBgAudio } from "../../utils/fade-audio";
import SoundManager from "../../utils/managers/sound-manager";
import { useLogoutMutation } from "../../auth/data-access/store/services/auth.service";
import { setMessage } from "../../shared/data-access/store/message/messageSlice";

export function Menu() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const init = useAppSelector(selectInit);
  const lang = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);
  const pathname = useLocation().pathname;
  const { send: finishGame, game, meta } = useGameEvents("finish_game");
  const { token, uuid } = meta;
  const { reset: resetLocals } = useLocals();
  const [logoutPost, { error: logoutError, isSuccess: logoutIsSuccess }] =
    useLogoutMutation();

  const commonParams = useMemo(() => {
    if (!game?.id) {
      return false;
    }
    return {
      playedGame: {
        id: game.id,
      },
      userToken: token,
      uuid,
    };
  }, [game?.id, token, uuid]);

  const navigationGuard = async (callback: () => void) => {
    if (gameRoutes.some((keyword) => pathname.includes(keyword))) {
      if (!commonParams) {
        return;
      }
      if (confirm("Are you sure you want to end this game?")) {
        resetLocals();
        dispatch(setIsLoadingPage(true));
        await finishGame({ ...commonParams, force_finish: true });
        dispatch(reset());
        dispatch(setIsLoadingPage(false));
        callback();
      }
    } else {
      callback();
    }
  };

  const handleMenuClick = (route = "/") => {
    navigationGuard(() => {
      dispatch(setModal({ modal: "menu", value: false }));
      setTimeout(() => {
        navigate(route);
      }, 300);
    });
  };

  useEffect(() => {
    if (logoutError) {
      console.log(logoutError);
      const errorMsg =
        (logoutError as { data?: Error })?.data?.message ||
        checkTranslationKey(translations[lang]?.Error, "Error!");
      if (errorMsg) {
        dispatch(setMessage({ content: errorMsg, type: "error" }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoutError]);

  useEffect(() => {
    if (logoutIsSuccess) {
      dispatch(logout());
    }
  }, [logoutIsSuccess]);

  return (
    <Background type="onboarding">
      <div id="menu">
        <div className="menu-content">
          <div className="header-transparent">
            <img
              className="logo-menu"
              src="/img/batteup-logo-2x.png"
              alt={checkTranslationKey(
                translations[lang]?.BattleUp_Logo,
                "BattleUp Logo"
              )}
            />
            <ButtonClose
              onClick={() =>
                dispatch(setModal({ modal: "menu", value: false }))
              }
            />
          </div>
          <div className="menu-list">
            <ul>
              <div className="list-ellipse"></div>
              <li onClick={() => handleMenuClick("/")}>
                {checkTranslationKey(translations[lang]?.Home, "Home")}
              </li>
              <div className="list-ellipse"></div>
              <li onClick={() => handleMenuClick("/prizes")}>
                {checkTranslationKey(translations[lang]?.Prizes, "Prizes")}
              </li>
              <div className="list-ellipse"></div>
              <li onClick={() => handleMenuClick("/store")}>
                {checkTranslationKey(translations[lang]?.Store, "Store")}
              </li>
              <div className="list-ellipse"></div>
              <li onClick={() => handleMenuClick("/profile")}>
                {checkTranslationKey(translations[lang]?.Profile, "Profile")}
              </li>
              <div className="list-ellipse"></div>
              <li onClick={() => handleMenuClick("/leaderboard")}>
                {checkTranslationKey(translations[lang]?.Ranking, "Ranking")}
              </li>
              <div className="list-ellipse"></div>
              <li
                onClick={() => {
                  handleMenuClick("/onboarding");
                  fadeOutBgAudio(SoundManager.ambientSounds.home);
                }}
              >
                {checkTranslationKey(translations[lang]?.Tutorial, "Tutorial")}
              </li>
              <div className="list-ellipse"></div>
              <li onClick={() => handleMenuClick("/faq")}>
                {checkTranslationKey(translations[lang]?.F_A_Q, "F.A.Q")}
              </li>
              <div className="list-ellipse"></div>
              <li
                onClick={() =>
                  navigationGuard(() => {
                    logoutPost();
                    localStorage.removeItem("accessToken");
                    localStorage.removeItem("tokenForLogin");
                    dispatch(setModal({ modal: "menu", value: false }));
                    fadeOutBgAudio(SoundManager.ambientSounds.home);
                  })
                }
              >
                {checkTranslationKey(translations[lang]?.Logout, "Logout")}
              </li>
              <div className="list-ellipse"></div>
            </ul>
          </div>
          <img className="menu-notice" src="/img/menu-notice.png" alt={""} />
          <a
            href={init?.content?.terms[lang]}
            target="_blank"
            className="menu-terms"
          >
            {checkTranslationKey(
              translations[lang]?.Terms_and_Conditions,
              "Terms and Conditions"
            )}
          </a>
        </div>
      </div>
    </Background>
  );
}

export default Menu;
