import { ComponentProps } from "react";
import "./question-prize.scss";
import cx from "classnames";
import { Category } from "../../../shared/data-access/store/game/types/socket-events/category";

export interface PrizeQuestionProps extends ComponentProps<"div"> {
  category: Category["icon"];
  icon: "diamonds";
}

export function PrizeQuestion({
  icon,
  category,
  children,
  ...props
}: PrizeQuestionProps) {
  return (
    <div {...props} className={cx("prize-question-container", props.className)}>
      {category && (
        <img id="category" src={category} alt="category" height={40} />
      )}
      Win{" "}
      <img id="prize" src={`../img/icns/${icon}.png`} alt={icon} height={30} />{" "}
      <span>{children}</span>
    </div>
  );
}

export default PrizeQuestion;
