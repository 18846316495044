import { createApi } from "@reduxjs/toolkit/dist/query/react";
import baseQuery from "../../../../shared/data-access/store/baseQuery";
import { Prizes } from "../../../../shared/interfaces/prize.types";

export const prizesApi = createApi({
  reducerPath: "prizesApi",
  baseQuery,
  endpoints: (builder) => ({
    getPrizes: builder.query<Prizes, void>({
      query: () => "repository/prizes",
    }),
  }),
});

export const { useGetPrizesQuery } = prizesApi;
