import { Button } from "../../../shared/ui/button";

import "./modal-promotional-video.scss";
import { useEffect, useRef, useState } from "react";
import cx from "classnames";

export interface ModalPromotionalVideoProps {
  title: string;
  buttonPrimary: {
    text: string;
    action?: () => void;
  };
  buttonSecondary: {
    text: string;
    action?: () => void;
  };
  onVideoStart?: () => void;
  onVideoEnd?: () => void;
}

const ModalPromotionalVideo = ({
  buttonPrimary,
  buttonSecondary,
  title,
  onVideoStart,
  onVideoEnd,
}: ModalPromotionalVideoProps) => {
  const [showVideo, setShowVideo] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const video = videoRef.current;
    if (video !== null && showVideo) {
      const checkVideoEnd = () => {
        // Check if the video has been played fully
        if (video.currentTime >= video.duration) {
          console.log("User watched the whole video without skipping.");
          // You can perform further actions here, like sending analytics or unlocking content
          onVideoEnd?.();
        } else {
          console.log("User skipped parts of the video.");
        }
      };

      video?.play();
      video.addEventListener("ended", checkVideoEnd);

      return () => {
        video.removeEventListener("ended", checkVideoEnd);
      };
    }
  }, [showVideo]);

  const startVideo = () => {
    onVideoStart?.();
    setShowVideo(true);
  };

  return (
    <div className={cx("modal-promotional-video", showVideo && "video-mode")}>
      <div className="modal-content">
        {showVideo ? (
          <video
            controls={false}
            ref={videoRef}
            className={"modal-video"}
            autoPlay={true}
          >
            <source src="../videos/promo-video.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <>
            <div
              className="modal-bg"
              style={{ backgroundImage: "url(../img/temp-bg.png)" }}
            >
              <div className="overlay"></div>
            </div>
            <h3 className={"title"}>{title}</h3>
            <img
              src="../img/icns/play-btn.png"
              alt=""
              className="play-btn"
              onClick={startVideo}
            />

            <div className="modal-btn-container">
              <Button
                buttonType="ladder-white"
                type="submit"
                value="Submit"
                onClick={buttonPrimary.action}
              >
                {buttonPrimary.text}
              </Button>

              <Button
                buttonType="success"
                type="submit"
                value="Submit"
                onClick={startVideo}
              >
                {buttonSecondary.text}
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ModalPromotionalVideo;
