import type { CommonAnswersType } from "../gameplay-answer";
import "./gameplay-image-with-text.scss";
import cx from "classnames";

export type GameplayAnswerImageTextProps = CommonAnswersType & {
  gameplay: "image-with-text";
  options: {
    imageSrc: string;
    text: string;
  };
};

export function GameplayAnswerImageText({
  options: { imageSrc, text },
  status = "default",
  ...props
}: GameplayAnswerImageTextProps) {
  return (
    <button
      {...props}
      className={cx("gameplay-image", props.className, {
        correct: status === "correct",
        wrong: status === "wrong",
      })}
    >
      {status !== "default" ? (
        <img
          className="answer-badge"
          src={`../img/icns/answer-${status}.png`}
          height={54}
          width={54}
          alt={status}
        />
      ) : null}
      <div className="image-text-area">
        <div className="image-area-answer">
          <img src={imageSrc} />
        </div>
        <div className="text-area-answer">{text}</div>
      </div>
    </button>
  );
}

export default GameplayAnswerImageText;
