import { createApi } from "@reduxjs/toolkit/dist/query/react";
import baseQuery from "../../../../shared/data-access/store/baseQuery";
import {
  BannersResponse,
  BuyCreditPackageArg,
  CreditPackagesResponse,
} from "../../../../shared/interfaces/general.types";

export const storeApi = createApi({
  reducerPath: "storeApi",
  baseQuery,
  endpoints: (builder) => ({
    getBanners: builder.query<BannersResponse, void>({
      query: () => "repository/banners",
    }),
    getCreditPackages: builder.query<CreditPackagesResponse, void>({
      query: () => "repository/credit-packages",
    }),
    buyCreditPackage: builder.mutation<
      CreditPackagesResponse,
      BuyCreditPackageArg
    >({
      query: (packageId) => {
        return {
          url: "user/credits/buy",
          method: "POST",
          body: packageId,
        };
      },
    }),
  }),
});

export const {
  useGetBannersQuery,
  useGetCreditPackagesQuery,
  useBuyCreditPackageMutation,
} = storeApi;
