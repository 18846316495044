import * as React from "react";
import { ComponentProps } from "react";
import "./hints.scss";
import cx from "classnames";

export interface HintsProps extends ComponentProps<"button"> {
  icon: string;
  title: string;
  bottom?: Array<JSX.Element> | JSX.Element | string;
}

export function Hints({ icon, title, bottom, ...props }: HintsProps) {
  return (
    <div className={cx("hints-container", props.className)}>
      <div className="hints-content">
        <div className="hint">
          <button {...props} className="hint-btn">
            <div className="hint-img"></div>
            <img src={`../img/hints/${icon}.png`} alt={title} />
            {title}
          </button>
          <div className="slot-bottom">{bottom}</div>
        </div>
      </div>
    </div>
  );
}

export default Hints;
