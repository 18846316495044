import React, { useCallback, useEffect, useRef, useState } from "react";
import { Background } from "../../../../shared/ui/background";
import { Modal } from "../../../../shared/ui/modal";
import { NavBar } from "../../../../shared/ui/navbar";
import { BonusResultModal } from "../../../ui/bonus-result";
import { GameplayLayout } from "../../../ui/gameplay-layout";
import { PrizeQuestion } from "../../../ui/question-prize";
import "../gameplay-bonus.scss";
import { AnimatePresence, motion } from "framer-motion";
import { bonusResultContainerVariants } from "../../../utils/animation-states";
import { useNavigate } from "react-router-dom";
import { useGameEvents } from "../../../../shared/data-access/store/game/hooks/use-game-events";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { selectLanguage } from "../../../../shared/data-access/store/general/generalSlice";
import { useCountdown } from "../../../../utils/hooks/use-countdown";
import { differenceInMilliseconds } from "date-fns/esm";
import { reset } from "../../../../shared/data-access/store/game/gameSlices";
import { TIMER_ANIMATION_DELAY } from "../../../../utils/constants";
import SoundManager from "../../../../utils/managers/sound-manager";
import Game from "./game/game";
import { CommonParams } from "../gameplay-bonus";

type FindItemProps = {
  handleError: (error: unknown) => void;
  commonParams: CommonParams;
};

export function FindItem({ handleError, commonParams }: FindItemProps) {
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data: game } = useGameEvents("init_bonus_game");
  const { send: startGame, data: startGameData } =
    useGameEvents("start_bonus_game");
  const { send: finishGame, data: gameResults } =
    useGameEvents("finish_bonus_game");
  const sceneImgRef = useRef<null | HTMLImageElement>(null);

  const results = gameResults?.data;
  const [circleCoords, setCircleCoords] = useState({ x: 0, y: 0, radius: 0 });

  const lang = useAppSelector(selectLanguage);

  const roundData = startGameData?.data;

  const timer = roundData?.timer;

  const { timeLeft, start, stop, getUntilDateTime, clean } = useCountdown(
    timer && timer + TIMER_ANIMATION_DELAY
  );

  const currentTimeRef = useRef<number>();
  const answeredRef = useRef(false);

  const handleAnswer = useCallback(
    async (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const untilDateTime = getUntilDateTime();
      if (
        !commonParams ||
        !untilDateTime ||
        !timer ||
        currentTimeRef.current === undefined ||
        answeredRef.current
      ) {
        return;
      }

      const coords = event ? getRelativeCoords(event) : { x: 0, y: 0 };
      const scaleRatio = await getScaleRatioBetweenImg();
      const adjustedCoords = {
        x: coords.x * scaleRatio,
        y: coords.y * scaleRatio,
      };
      answeredRef.current = true;
      clean();

      try {
        const finishGameRes = await finishGame({
          ...commonParams,
          answer: event ? [adjustedCoords.x, adjustedCoords.y] : -1,
          answer_time: Math.abs(
            timer * 1000 - differenceInMilliseconds(untilDateTime, new Date())
          ),
        });
        if (finishGameRes) {
          const correctAnswerDetails =
            finishGameRes?.data?.answer?.correct_answer_details;
          if (correctAnswerDetails) {
            const adjustedRadius =
              typeof correctAnswerDetails === "object"
                ? correctAnswerDetails.radius / scaleRatio
                : 0;

            const circleBorderSize = 2;
            setCircleCoords({
              x:
                typeof correctAnswerDetails === "object"
                  ? correctAnswerDetails.origin_x / scaleRatio -
                    adjustedRadius -
                    circleBorderSize
                  : 0,
              y:
                typeof correctAnswerDetails === "object"
                  ? correctAnswerDetails.origin_y / scaleRatio -
                    adjustedRadius -
                    circleBorderSize
                  : 0,
              radius: adjustedRadius,
            });
          }
        }
      } catch (e) {
        handleError(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [commonParams, getUntilDateTime, timer]
  );

  useEffect(() => {
    if (!results) {
      return;
    }

    const showReward = () => {
      setTimeout(() => {
        setShowInfo(true);
      }, 4000);
    };

    stop();
    showReward();
  }, [results]);

  useEffect(() => {
    if (timeLeft === undefined) {
      return;
    }

    if (timeLeft < 5) {
      SoundManager.vfxSounds.clock.playbackRate = 1.3;
    }

    if (timeLeft > 0) {
      currentTimeRef.current = timeLeft;
      SoundManager.vfxSounds.clock.play();
    } else {
      if (timeLeft === 0) {
        if (currentTimeRef.current && currentTimeRef.current > 0) {
          currentTimeRef.current = 0;
        }
      } else {
        if (!currentTimeRef.current) {
          currentTimeRef.current = 0;
        }
        stop();
        handleAnswer();
      }
    }
  }, [timeLeft]);

  useEffect(() => {
    if (!commonParams) {
      return;
    }

    (async () => {
      try {
        if (commonParams) {
          await startGame(commonParams);
          start();
        }
      } catch (error) {
        handleError(error);
      }
    })();
  }, [commonParams]);

  useEffect(() => {
    if (showInfo && results) {
      if (results.won && results.answer.correct) {
        SoundManager.vfxSounds.win.play();
      } else {
        SoundManager.vfxSounds.lose.play();
      }
    }
  }, [showInfo, results]);

  useEffect(() => {
    if (answeredRef.current) {
      SoundManager.vfxSounds.clock.pause();
      SoundManager.vfxSounds.clock.playbackRate = 1;
      SoundManager.vfxSounds.clock.currentTime = 0;
    }
  }, [answeredRef.current]);

  const handleContinue = useCallback((showModal: boolean) => {
    navigate("/");
    dispatch(reset());
    setShowInfo(showModal);
  }, []);

  const getRelativeCoords = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    const bounds = event.currentTarget.getBoundingClientRect();
    const x = event.clientX - bounds.left;
    const y = event.clientY - bounds.top;
    return { x, y };
  };

  const getScaleRatioBetweenImg = async (): Promise<number> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = function () {
        const widthOriginal = img.width;
        const widthCurrent = sceneImgRef?.current?.width || 0;

        if (!widthCurrent || !widthOriginal) {
          reject("Image not loaded");
        }
        resolve(widthOriginal / widthCurrent);
        // resolve(((widthOriginal - widthCurrent) / widthOriginal) * 100);
      };

      if (roundData?.canvas_url) {
        img.src = roundData?.canvas_url;
      }
    });
  };

  const generatePrizeDescription = (
    prizes: Array<{ type: string; value: number | string }>
  ) => {
    const filteredPrizes = prizes.filter((prize) => prize.value);
    let text = "";
    filteredPrizes.forEach((prize, index) => {
      text = (index > 0 ? "&" : "") + prize.value + " " + prize.type;
    });
    return text;
  };

  return (
    <div className="gameplay-page">
      <Background type="generic" category={"generic"}>
        <NavBar hideMenu slotEnd="lives" />
        <GameplayLayout
          timer={currentTimeRef.current}
          prizes={
            <PrizeQuestion icon="diamonds" category={""}>
              {300}
            </PrizeQuestion>
          }
          question={
            roundData?.title && roundData?.title[lang]
              ? roundData?.title.en
              : "Loading game..."
          }
        >
          <Game
            handleAnswer={handleAnswer}
            sceneImgRef={sceneImgRef}
            sceneImgUrl={roundData?.canvas_url || ""}
            hiddenObjectUrl={roundData?.hidden_object_url || ""}
            circleCoords={circleCoords}
            won={Boolean(results?.won)}
          />
        </GameplayLayout>
      </Background>
      <AnimatePresence>
        {showInfo && results && (
          <motion.div
            variants={bonusResultContainerVariants}
            initial={"hidden"}
            animate={"visible"}
            exit={"hidden"}
            className="modal-presence-wrapper"
          >
            <Modal
              show={showInfo}
              setShow={handleContinue}
              hideCloseButton
              defaultAnimations={false}
              disableClickOutside
            >
              {/* @TODO: @VladS, Adrian please fix the modal so it shows and redirects properly */}
              <BonusResultModal
                bonusCategory="music"
                subtitle={game?.data?.bonus_game?.title[lang] || ""}
                title={
                  results.won && results.answer.correct ? "You won" : "You Lost"
                }
                awardQuantityLives={results.prizes?.lives || 0}
                awardQuantityPoints={results.prizes?.points || 0}
                prizeImage={results.prizes?.prize?.image}
                prizeText={results.prizes?.prize?.name[lang]}
                text={
                  results.won && results.answer.correct
                    ? `You have just won`
                    : "Move faster next time"
                }
                boldText={
                  results.won && results.answer.correct
                    ? generatePrizeDescription([
                        { type: "lives", value: results?.prizes?.lives },
                        { type: "diamonds", value: results?.prizes?.points },
                        { type: "", value: results?.prizes?.prize?.name[lang] },
                      ])
                    : ""
                }
                buttonText={
                  results.won && results.answer.correct ? "Collect" : "Continue"
                }
                iconSlotLeft={
                  results.prizes.lives
                    ? "lives"
                    : results.prizes.points
                    ? "diamonds"
                    : undefined
                }
                iconSlotRight={
                  results.prizes.points
                    ? "diamonds"
                    : results.prizes.lives
                    ? "lives"
                    : undefined
                }
                mainIcon={
                  results.won && results.answer.correct ? "ribon" : "lost"
                }
                onClick={() => handleContinue(false)}
              />
            </Modal>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default FindItem;
