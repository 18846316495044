import { checkiOS } from "./checkIOS";

export function fadeOutBgAudio(
  bgSound: HTMLAudioElement,
  soundToPlay?: HTMLAudioElement
) {
  if (checkiOS()) {
    bgSound.pause();
    if (soundToPlay) {
      soundToPlay.play();
    }
  } else {
    const fadeAudio = setInterval(function () {
      if (bgSound.volume <= 0.2) {
        bgSound.volume = 0;
        bgSound.currentTime = 0;
        bgSound.pause();
        if (soundToPlay) {
          soundToPlay.play();
        }
        clearInterval(fadeAudio);
      } else if (bgSound.volume !== 0.0) {
        bgSound.volume -= 0.1;
      }
    }, 50);
  }
}
