import "./badge-rewards.scss";
import { useAppSelector } from "../../../hooks/hooks";
import {
  selectLanguage,
  selectTranslations,
} from "../../data-access/store/general/generalSlice";
import { checkTranslationKey } from "../../../utils/translation";

export function BadgeRewards() {
  const lang = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);

  return (
    <p className="badge-rewards">
      {checkTranslationKey(translations[lang]?.REWARDS, "REWARDS")}
    </p>
  );
}
