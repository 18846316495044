import { Socket, io } from "socket.io-client";
import { Rest } from "../../../../../utils/typescript/arrays/rest";
import { ListenerService } from "./listener.service";

export class SocketService {
  private static instance?: SocketService;

  socket: Socket | undefined;
  constructor(
    private url: `wss://${string}` = process.env
      .REACT_APP_WS_URL as `wss://${string}`
  ) {
    if (SocketService.instance) {
      return SocketService.instance;
    }
    SocketService.instance = this;
    this.connect();
  }

  public connect(): asserts this is this & { socket: Socket } {
    if (!this.socket) {
      this.socket = io(this.url, {
        transports: ["websocket"],
      });
    }
  }

  public disconnect(): asserts this is this & { socket: undefined } {
    if (this.socket) {
      this.socket.close();
      this.socket = undefined;
    }
  }

  public emit(...args: Rest<Parameters<Socket["emit"]>>) {
    this.connect();
    this.socket.emit("request", ...args);
  }

  private _on(...args: Parameters<Socket["on"]>) {
    this.connect();
    this.socket.on(...args);
  }

  public on(...args: Parameters<Socket["on"]>) {
    const [ev, callback] = args;
    const listenerService = new ListenerService(ev);
    if (!listenerService.size) {
      this._on(ev, listenerService.sendToListeners.bind(listenerService));
    }
    return listenerService.on(callback);
  }

  public off(id: string) {
    this.connect();
    const [eventType] = id.split("-");
    if (!eventType) {
      throw new Error("Invalid ID");
    }
    const listenerService = new ListenerService(eventType);
    listenerService.off(id);
    if (!listenerService.size) {
      this.socket.off(
        eventType,
        listenerService.sendToListeners.bind(listenerService)
      );
    }
  }
}
