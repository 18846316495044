import { ComponentProps } from "react";
import "./badge.scss";

export interface BadgeProps extends ComponentProps<"div"> {
  children?: Array<JSX.Element> | JSX.Element | string | number;
  type?: "success" | "danger" | "primary" | "win" | "lost";
  aspect?: "rectangle" | "circle";
}

export function Badge({
  children,
  type = "primary",
  aspect = "rectangle",
  className,
  ...props
}: BadgeProps) {
  return (
    <>
      <div
        className={`badge badge-${type} badge-${aspect} ${className}`}
        {...props}
      >
        <span>{children}</span>
      </div>
    </>
  );
}

export default Badge;
