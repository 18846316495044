import { Step } from "react-joyride";
import { useGetMiniTutorialsQuery } from "../../shared/data-access/store/general/services/general.service";
import { useAppSelector } from "../../hooks/hooks";
import {
  selectInit,
  selectLanguage,
} from "../../shared/data-access/store/general/generalSlice";
import { selectIsAuthenticated } from "../../auth/data-access/store/authSlice";

const useMiniTutorials = () => {
  const lang = useAppSelector(selectLanguage);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const { data: miniTutorialsData } = useGetMiniTutorialsQuery(undefined, {
    skip: !isAuthenticated,
  });
  const init = useAppSelector(selectInit);

  const getTitleByCode = (code: string) => {
    return (
      miniTutorialsData?.items?.find((item) => item.code === code)?.title[
        lang
      ] || ""
    );
  };

  const getDescriptionByCode = (code: string) => {
    return (
      miniTutorialsData?.items?.find((item) => item.code === code)?.text[
        lang
      ] || ""
    );
  };

  const stepsHome: Array<Step> = [
    {
      disableBeacon: true,
      target: "#home .user-points .user",
      title: getTitleByCode("hp_edit_profile_button"),
      content: getDescriptionByCode("hp_edit_profile_button"),
    },
    {
      disableBeacon: true,
      target: ".area.diamonds",
      title: getTitleByCode("hp_diamonds"),
      content: getDescriptionByCode("hp_diamonds"),
      placement: "top",
    },
    {
      disableBeacon: true,
      target: ".area.coins",
      title: getTitleByCode("hp_coins"),
      content: getDescriptionByCode("hp_coins"),
    },
    {
      disableBeacon: true,
      target: ".area.level",
      title: getTitleByCode("hp_level_up"),
      content: getDescriptionByCode("hp_level_up"),
    },
    {
      disableBeacon: true,
      target: ".area.lives",
      title: getTitleByCode("hp_lives"),
      content: getDescriptionByCode("hp_lives"),
    },
    {
      disableBeacon: true,
      target: ".area.strikes",
      title: getTitleByCode("hp_strikes_achieved"),
      content: getDescriptionByCode("hp_strikes_achieved"),
    },
    {
      disableBeacon: true,
      target: "#home .button-success-play",
      title: getTitleByCode("hp_start_playing"),
      content: getDescriptionByCode("hp_start_playing"),
    },
    ...(!init?.features?.disableBuyCredits
      ? [
          {
            disableBeacon: true,
            target: ".game-store",
            title: getTitleByCode("hp_access_store"),
            content: getDescriptionByCode("hp_access_store"),
          },
        ]
      : []),
  ];

  const stepsLadder: Array<Step> = [
    {
      disableBeacon: true,
      target:
        ".ladder-page .ladder-button-container:nth-child(10) .ladder-button .count-start .icon-position",
      title: getTitleByCode("ladder_diamonds_awarded"),
      content: getDescriptionByCode("ladder_diamonds_awarded"),
    },
    {
      disableBeacon: true,
      target:
        ".ladder-page .ladder-button-container:nth-child(5) .ladder-button .count-end",
      title: getTitleByCode("ladder_safeguard_your_diamonds"),
      content: getDescriptionByCode("ladder_safeguard_your_diamonds"),
    },
    {
      disableBeacon: true,
      target: ".player-left",
      title: getTitleByCode("ladder_player_avatar"),
      content: getDescriptionByCode("ladder_player_avatar"),
    },
    {
      disableBeacon: true,
      target: ".player-right",
      title: getTitleByCode("ladder_opponent_avatar"),
      content: getDescriptionByCode("ladder_opponent_avatar"),
    },
  ];

  const stepsGame: Array<Step> = [
    {
      disableBeacon: true,
      target: ".gameplay-page #navbar .chip-lives",
      title: getTitleByCode("in_game_lives"),
      content: getDescriptionByCode("in_game_lives"),
    },
    {
      disableBeacon: true,
      target: ".gameplay-page .gameplay-container .timer-position",
      title: getTitleByCode("in_game_timer"),
      content: getDescriptionByCode("in_game_timer"),
    },
    {
      disableBeacon: true,
      target: ".gameplay-page .hint-area .hints-container:nth-of-type(1)",
      title: getTitleByCode("in_game_hide"),
      content: getDescriptionByCode("in_game_hide"),
    },
    {
      disableBeacon: true,
      target: ".gameplay-page .hint-area .hints-container:nth-of-type(2)",
      title: getTitleByCode("in_game_ask_genie"),
      content: getDescriptionByCode("in_game_ask_genie"),
    },
    {
      disableBeacon: true,
      target: ".gameplay-page .hint-area .hint-time-wrapper",
      title: getTitleByCode("in_game_add_time"),
      content: getDescriptionByCode("in_game_add_time"),
    },
    {
      disableBeacon: true,
      target: ".gameplay-page .hint-area .hints-container:nth-of-type(4)",
      title: getTitleByCode("in_game_skip"),
      content: getDescriptionByCode("in_game_skip"),
    },
  ];

  if (!miniTutorialsData) {
    return { data: null };
  }

  return {
    data: {
      stepsHome,
      stepsLadder,
      stepsGame,
    },
  };
};

export default useMiniTutorials;
