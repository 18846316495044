import { createSlice } from "@reduxjs/toolkit";
import { prizesApi } from "./services/prizes.service";
import { Prizes } from "../../../shared/interfaces/prize.types";
import { RootState } from "../../../shared/data-access/store/store";

type prizesState = {
  prizes: Prizes | null;
};

const initialState: prizesState = {
  prizes: null,
};

const prizesSlice = createSlice({
  name: "prizes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      prizesApi.endpoints.getPrizes.matchFulfilled,
      (state, { payload }) => {
        state.prizes = payload;
      }
    );
  },
});

const { reducer } = prizesSlice;

export const selectPrizes = (state: RootState) => state.prizes.prizes;

export default reducer;
