import React from "react";
import cx from "classnames";

import "./game.scss";

type GameProps = {
  handleAnswer: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  sceneImgRef: React.LegacyRef<HTMLImageElement>;
  sceneImgUrl: string;
  hiddenObjectUrl: string;
  circleCoords?: { x: number; y: number; radius: number };
  won: boolean;
};

const Game = ({
  handleAnswer,
  sceneImgRef,
  sceneImgUrl,
  hiddenObjectUrl,
  circleCoords,
  won,
}: GameProps) => {
  return (
    <div className="find-item-game">
      <div className="object-to-find">
        <img src={hiddenObjectUrl} alt="Hidden object" />
        <p>
          Hidden <br />
          object
        </p>
      </div>
      <div className="scene-wrapper" onClick={handleAnswer}>
        <img src={sceneImgUrl} alt="Click to find item" ref={sceneImgRef} />
        {circleCoords && (
          <div
            className={cx("circle", won && "green")}
            style={{
              left: circleCoords.x,
              top: circleCoords.y,
              height: circleCoords.radius * 2,
              width: circleCoords.radius * 2,
            }}
          ></div>
        )}
      </div>
    </div>
  );
};

export default Game;
