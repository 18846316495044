import { ComponentProps } from "react";
import "./prize-card.scss";

export interface PrizeCardProps extends ComponentProps<"div"> {
  image: string;
  title: string;
  subtitle: string;
  type?: "large" | "small";
}

export function PrizeCard({
  image,
  title,
  subtitle,
  type = "small",
  ...props
}: PrizeCardProps) {
  return (
    <>
      {type === "large" ? (
        <div className="prize-card-container" {...props}>
          <div className="prize-card-header">
            <div className="prize-card-header-background">
              <div className="prize-stars"></div>
            </div>
            <img src={image} alt={subtitle} height={120} />
          </div>

          <div className="prize-card-content">
            <div className="prize-title">{title}</div>
            <div className="prize-subtitle">{subtitle}</div>
          </div>
        </div>
      ) : (
        <div className="prize-card-container-small" {...props}>
          <div className="prize-left-slot">
            <div className="prize-title-small">{title}</div>
            <div className="prize-subtitle-small">{subtitle}</div>
          </div>
          <div className="prize-right-slot">
            <img src={image} alt={subtitle} height={130} />
            <div className="background-right">
              <div className="prize-stars-sm"></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PrizeCard;
