import { ComponentProps } from "react";
import { motion, Variants } from "framer-motion";
import { Background } from "../../../shared/ui/background";
import { PrizeToWin } from "../../../shared/ui/prize-to-win";
import "./auth.scss";

export interface AuthProps extends ComponentProps<"div"> {
  header: Array<JSX.Element> | JSX.Element | string;
  hasFooter?: boolean;
}

const logoVariants: Variants = {
  hidden: {
    opacity: 0,
    scale: 0,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.3,
      ease: "easeOut",
    },
  },
};

const contentVariants: Variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.3,
      delay: 0.3,
      ease: "easeOut",
    },
  },
};

export function Auth({
  children,
  header,
  hasFooter = true,
  className,
}: AuthProps) {
  return (
    <Background type="home">
      <div className="auth-content">
        <div className="header-transparent">
          <motion.img
            src="/img/batteup-logo.png"
            alt="BattleUp Logo"
            height={52}
            className="logo"
            variants={logoVariants}
            initial="hidden"
            animate="visible"
          />
        </div>
        {header}
        <motion.div
          className={`auth-shell ${className}`}
          variants={contentVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="auth-card">
            <div className="card-content">{children}</div>
            {hasFooter ? (
              <div className="card-footer">
                <div className="card-prizes">
                  <PrizeToWin></PrizeToWin>
                </div>
              </div>
            ) : null}
          </div>
        </motion.div>
      </div>
    </Background>
  );
}

export default Auth;
