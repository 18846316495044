import { Button } from "../../../shared/ui/button";
import { Title } from "../../../shared/ui/title";
import { Auth } from "../auth/auth";
import cx from "classnames";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";

import "./sign-up.scss";
import {
  useLazyGetUserQuery,
  useSubscribePromptMutation,
} from "../../data-access/store/services/auth.service";
import { useEffect, useState } from "react";
import { setMessage } from "../../../shared/data-access/store/message/messageSlice";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { Modal } from "../../../shared/ui/modal";
import { SmsSent } from "../../../shared/ui/modal-notifications/sms-sent";
import {
  selectInit,
  selectLanguage,
  selectTranslations,
} from "../../../shared/data-access/store/general/generalSlice";
import { useNavigate } from "react-router-dom";
import { UpstreamServiceBanner } from "../../../shared/ui/upstream-service-banner";
import { checkTranslationKey } from "../../../utils/translation";
import { selectUser } from "../../data-access/store/authSlice";

export function SignUp() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [
    subscribePrompt,
    {
      isLoading: subscribePromptIsLoading,
      error: subscribePromptError,
      isSuccess: subscribePromptIsSuccess,
    },
  ] = useSubscribePromptMutation();
  const init = useAppSelector(selectInit);
  const [showModal, setShowModal] = useState(false);
  const lang = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);
  const user = useAppSelector(selectUser);
  const [getUser] = useLazyGetUserQuery();

  useEffect(() => {
    if (init?.users?.subscribeMethod === "direct") {
      navigate("/auth/subscribe", { replace: true });
    }
  }, []);

  useEffect(() => {
    if (subscribePromptError) {
      const errorMsg =
        (subscribePromptError as { data?: Error })?.data?.message ||
        checkTranslationKey(translations[lang]?.Error, "Error!");
      if (errorMsg) {
        dispatch(setMessage({ content: errorMsg, type: "error" }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribePromptError]);

  useEffect(() => {
    if (subscribePromptIsSuccess) {
      if (user) {
        getUser();
      } else {
        setShowModal(true);
      }
    }
  }, [subscribePromptIsSuccess]);

  const prefix = init?.users?.msisdn?.prefix || "";
  const phoneMaxLength = init?.users?.msisdn?.maxLength || 9;
  const phoneMinLength = init?.users?.msisdn?.minLength || 9;

  const SignUpSchema = Yup.object().shape({
    phone: Yup.string()
      .matches(
        /^[0-9]+$/,
        checkTranslationKey(
          translations[lang]?.The_phone_number_must_be_only_digits,
          "The phone number must be only digits"
        )
      )
      .min(
        phoneMinLength,
        checkTranslationKey(
          translations[lang]?.The_phone_number_is_too_short,
          "The phone number is too short."
        )
      )
      .max(
        phoneMaxLength,
        checkTranslationKey(
          translations[lang]?.The_phone_number_is_too_long,
          "The phone number is too long."
        )
      )
      .required(
        checkTranslationKey(
          translations[lang]?.The_phone_number_is_required,
          "The phone number is required."
        )
      ),
  });

  return (
    <div className="auth-page">
      <Auth
        className="auth-shell-content"
        header={
          <Title backURL="/auth/signin">
            {checkTranslationKey(translations[lang]?.Sign_Up, "Sign Up")}
          </Title>
        }
      >
        <div className="form-title">
          {checkTranslationKey(
            translations[lang]?.Create_your_BattleUp_account,
            "Create your BattleUp account"
          )}
        </div>
        <div className="form-container">
          <Formik
            initialValues={{ phone: "" }}
            validationSchema={SignUpSchema}
            onSubmit={(values) => {
              subscribePrompt({ msisdn: prefix + values.phone });
            }}
          >
            {({ errors, touched, values }) => (
              <>
                <Form>
                  <div
                    className={cx("form-input-row", {
                      danger: errors.phone && touched.phone,
                      success: !errors.phone,
                    })}
                  >
                    {init?.users?.subscribeMethod === "prompt" ? (
                      <>
                        {/*<label>Telephone number for your account</label>*/}
                        <div className="prefix-input">
                          <span className="prefix">+{prefix}</span>
                          <Field
                            type="tel"
                            name="phone"
                            className="half-input"
                            placeholder={checkTranslationKey(
                              translations[lang]?.Your_phone_number,
                              "Your phone number"
                            )}
                            autoComplete="off"
                            maxLength={phoneMaxLength}
                            required
                          />
                        </div>
                        <ErrorMessage name="phone">
                          {(msg) => (
                            <div className="error-msg">
                              <img
                                src="/img/icns/alert-fill.svg"
                                alt="alert"
                                className="color-svg"
                              />
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </>
                    ) : init?.users?.subscribeMethod === "static-text" ? (
                      <label>
                        {checkTranslationKey(
                          translations[lang]
                            ?.Send_an_SMS_to_and_you_will_receive_your_password,
                          "Send an SMS to 0743124214 and you will receive your password."
                        )}
                      </label>
                    ) : null}
                  </div>

                  {init?.users?.subscribeMethod === "prompt" ? (
                    <div className="submit">
                      <Button
                        buttonType="success"
                        type="submit"
                        value="Submit"
                        disabled={subscribePromptIsLoading}
                      >
                        {subscribePromptIsLoading
                          ? checkTranslationKey(
                              translations[lang]?.Sending_SMS,
                              "Sending SMS..."
                            )
                          : checkTranslationKey(
                              translations[lang]?.Send_SMS,
                              "Send SMS"
                            )}
                      </Button>
                    </div>
                  ) : init?.users?.subscribeMethod === "static-text" ? (
                    <div className="submit">
                      <Button
                        buttonType="success"
                        type="button"
                        onClick={() => navigate("/auth/sign-in")}
                      >
                        {checkTranslationKey(
                          translations[lang]?.Sign_in,
                          "Sign in"
                        )}
                      </Button>
                    </div>
                  ) : null}
                  <div className="terms-area">
                    {checkTranslationKey(
                      translations[lang]
                        ?.By_accepting_this_form_you_are_agreeing_to_the,
                      "By accepting this form you are agreeing to the"
                    )}{" "}
                    <a href={init?.content?.terms[lang]} target="_blank">
                      {checkTranslationKey(
                        translations[lang]?.Terms_and_Conditions,
                        "Terms and Conditions"
                      )}
                    </a>
                  </div>
                </Form>

                <Modal show={showModal} setShow={setShowModal} hideCloseButton>
                  <SmsSent
                    onButtonClick={() => navigate("/auth/sign-in")}
                    buttonText={checkTranslationKey(
                      translations[lang]?.SIGN_IN,
                      "SIGN IN"
                    )}
                    phone={`+${prefix} ${values.phone}`}
                    instructions={checkTranslationKey(
                      translations[lang]
                        ?.We_have_sent_you_an_SMS_with_instructions_to_this_number,
                      "We have sent you an SMS with instructions to this number:"
                    )}
                  />
                </Modal>
              </>
            )}
          </Formik>
        </div>
      </Auth>

      <div className="stream-service">
        <UpstreamServiceBanner />
      </div>
    </div>
  );
}

export default SignUp;
