import { Button } from "../../../shared/ui/button";
import { Title } from "../../../shared/ui/title";
import { Auth } from "../auth/auth";

import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import {
  selectInit,
  selectLanguage,
  selectTranslations,
} from "../../../shared/data-access/store/general/generalSlice";
import { useNavigate } from "react-router-dom";
import { checkTranslationKey } from "../../../utils/translation";
import { UpstreamServiceBanner } from "../../../shared/ui/upstream-service-banner";
import { logout } from "../../data-access/store/authSlice";

import "../subscribe/subscribe.scss";
import "./subscribe-guest.scss";

export function SubscribeGuest() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const init = useAppSelector(selectInit);
  const lang = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);

  return (
    <div className="auth-page subscribe-page">
      <Auth
        className="auth-shell-content"
        header={
          <Title>
            {checkTranslationKey(translations[lang]?.Subscribe, "Subscribe")}
          </Title>
        }
      >
        <div className="form-title form-title-login">
          {checkTranslationKey(
            translations[lang]?.Subscribe_now_to_enjoy,
            "Subscribe now to enjoy full access and win amazing prizes for only 5R/day!"
          )}
        </div>
        <div className="form-container">
          <div>
            <div className="btns-group">
              <Button
                buttonType="success"
                onClick={() => {
                  dispatch(logout());
                  navigate("/auth/sign-up");
                }}
              >
                Subscribe
              </Button>
              <Button
                buttonType="white"
                onClick={() => {
                  dispatch(logout());
                  navigate("/auth/sign-in");
                }}
              >
                {checkTranslationKey(
                  translations[lang]?.No_thanks,
                  "No thanks"
                )}
              </Button>
            </div>
            <p className="cost">
              {checkTranslationKey(
                translations[lang]?.Subscribe_cost,
                "Cost: XX/day"
              )}
            </p>
            <p className="cost-free">
              {checkTranslationKey(
                translations[lang]?.Subscribe_free_period,
                "First day free"
              )}
            </p>
            <div className="terms-area">
              {checkTranslationKey(
                translations[lang]?.By_subscribing_you_confirm,
                "By Subscribing, you confirm and agree to the"
              )}{" "}
              <a href={init?.content?.terms[lang]} target="_blank">
                {checkTranslationKey(
                  translations[lang]?.Terms_and_Conditions,
                  "Terms and Conditions"
                )}
              </a>
            </div>
          </div>
        </div>
      </Auth>
      <div className="stream-service">
        <UpstreamServiceBanner />
      </div>
    </div>
  );
}

export default SubscribeGuest;
