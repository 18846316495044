import { ComponentProps, useState } from "react";
import cx from "classnames";
import "./accordion-item.scss";

export interface AccordionItemProps extends ComponentProps<"div"> {
  title: string;
  description: string;
}

export function AccordionItem({
  className,
  title,
  description,
}: AccordionItemProps) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <li
      className={cx("accordion-item", className, {
        active: isOpen,
      })}
    >
      <h2 className="accordion-item-title">
        <button className="accordion-item-btn" onClick={toggle}>
          {title}
        </button>
      </h2>
      <div
        className={cx("accordion-item-container", {
          active: isOpen,
        })}
      >
        <div
          className="accordion-item-content"
          dangerouslySetInnerHTML={{ __html: description }}
        ></div>
      </div>
    </li>
  );
}

export default AccordionItem;
