import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

type UiState = {
  tutorial: {
    show: "home" | "ladder" | "gameplay" | false;
    step: number;
  };
  modals: {
    menu: boolean;
  };
  isLoadingPage: boolean;
};

const initialState: UiState = {
  tutorial: {
    show: false,
    step: 0,
  },
  modals: {
    menu: false,
  },
  isLoadingPage: false,
};

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setModal: (
      state,
      action: PayloadAction<{ modal: keyof UiState["modals"]; value: boolean }>
    ) => {
      state.modals[action.payload.modal] = action.payload.value;
    },
    setIsLoadingPage: (state, action: PayloadAction<boolean>) => {
      state.isLoadingPage = action.payload;
    },
    setTutorial: (state, action: PayloadAction<UiState["tutorial"]>) => {
      state.tutorial = action.payload;
    },
  },
});

const { reducer, actions } = uiSlice;

export const { setModal, setIsLoadingPage, setTutorial } = actions;

export const selectMenuModal = (state: RootState) => state.ui.modals.menu;
export const selectIsLoadingPage = (state: RootState) => state.ui.isLoadingPage;

export const selectTutorial = (state: RootState) => state.ui.tutorial;

export default reducer;
