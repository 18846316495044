import { HTMLMotionProps, motion, Variants } from "framer-motion";
import { useAppSelector } from "../../../hooks/hooks";
import { RoundAnswer } from "../../../shared/data-access/store/game/types/socket-events/start-round";
import {
  selectLanguage,
  selectTranslations,
} from "../../../shared/data-access/store/general/generalSlice";
import { checkTranslationKey } from "../../../utils/translation";
import { Button } from "../../../shared/ui/button";
import "./ask-genie-modal.scss";

const bgVariants: Variants = {
  hidden: {
    opacity: 0,
    scale: 0,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.3,
      delay: 0.3,
      scale: {
        type: "spring",
        bounce: 0.7,
      },
    },
  },
};

export interface GenieModalProps
  extends Omit<HTMLMotionProps<"button">, "children"> {
  introText: string;
  answer?: RoundAnswer;
  genieQuestion: string;
  genie: "male" | "female";
}

export function GenieModal({
  introText,
  answer,
  genieQuestion,
  genie,
  ...props
}: GenieModalProps) {
  const lang = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);

  if (!answer) {
    return null;
  }

  return (
    <>
      <motion.div
        className="modal-genie-container"
        variants={bgVariants}
        initial="hidden"
        animate="visible"
      >
        <div className="modal-genie-content">
          <div className="genie-content-container">
            {/*<img src="/img/onboarding/question1.png" alt="" />*/}
            <div className="genie-text">{introText}</div>
            {answer.type === "text" ? (
              <div className="genie-text genie-answer">
                {answer.value[lang] ?? answer.value.en}
              </div>
            ) : (
              <div className="genie-text genie-answer">
                <img src={answer.value} alt="" />
              </div>
            )}
            <div className="genie-text">{genieQuestion}</div>
            <div className="genie-text genie-thank-you">
              <Button buttonType="success" {...props}>
                {checkTranslationKey(
                  translations[lang]?.Genie_popup_button_text,
                  "THANK YOU GENIE"
                )}
              </Button>
            </div>
          </div>
          <div className="genie-lamp-container">
            <img
              src="../img/genie/lamp-clouds.png"
              alt=""
              width={327}
              className="genie-lamp"
            />
            <img
              src={`../img/genie/genie-${genie}.png`}
              alt=""
              height={277}
              className="genie"
            />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default GenieModal;
