import { Categories } from "../../../shared/data-access/store/game/types/socket-events/category";
import { Rewards } from "../../../shared/interfaces/rewards.type";
import { Background } from "../../../shared/ui/background";
import { Button } from "../../../shared/ui/button";
import "./bonus-result-modal.scss";
import { HTMLMotionProps, motion, Variants } from "framer-motion";

const contentVariants: Variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.3,
      delay: 1.5,
    },
  },
};

const bgVariants: Variants = {
  hidden: {
    opacity: 0,
    scale: 0,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.3,
      delay: 0.3,
      scale: {
        type: "spring",
        bounce: 0.7,
      },
    },
  },
};

const rayVariants: Variants = {
  hidden: {
    opacity: 0,
    scale: 0,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.3,
      delay: 0.5,
    },
  },
};

const badgeVariants: Variants = {
  hidden: {
    opacity: 0,
    scale: 0,
  },
  visible: {
    opacity: 1,
    scale: [1, 1.1, 1],
    transition: {
      duration: 0.2,
      delay: 0.8,
      scale: {
        repeat: 1,
      },
    },
  },
};

export interface BonusResultModalProps extends HTMLMotionProps<"button"> {
  bonusCategory: Categories;
  subtitle: string;
  title: string;
  awardQuantityLives: number;
  awardQuantityPoints: number;
  prizeImage?: string;
  prizeText?: string;
  text: string;
  boldText: string;
  buttonText: string;
  iconSlotLeft?: Rewards;
  iconSlotRight?: Rewards;
  mainIcon: "ribon" | "lost";
}

export function BonusResultModal({
  title,
  subtitle,
  bonusCategory,
  awardQuantityLives,
  awardQuantityPoints,
  prizeImage,
  prizeText,
  text,
  boldText,
  buttonText,
  iconSlotLeft,
  iconSlotRight,
  mainIcon,
  ...props
}: BonusResultModalProps) {
  return (
    <>
      <div className="bonus-result-container">
        <Background type={bonusCategory}>
          <div className="overlay-background">
            {mainIcon !== "lost" && (
              <motion.div
                className="green-lights"
                variants={rayVariants}
                initial={"hidden"}
                animate={"visible"}
              ></motion.div>
            )}
            <motion.div
              className="bonus-container"
              variants={bgVariants}
              initial={"hidden"}
              animate={"visible"}
            >
              <motion.div
                className="bonus-emoji"
                variants={badgeVariants}
                initial={"hidden"}
                animate={"visible"}
              >
                <img src={`../img/bonus/${mainIcon}.png`} height={100}></img>
              </motion.div>
              <motion.div
                className="bonus-content"
                variants={contentVariants}
                initial={"hidden"}
                animate={"visible"}
              >
                <div className="bonus-subtitle">{subtitle}</div>
                <div className="bonus-title">{title}</div>
                {mainIcon !== "lost" && (
                  <div className="bonus-award">
                    {iconSlotLeft && (
                      <img
                        src={`../img/icns/${iconSlotLeft}.png`}
                        alt={`${iconSlotLeft}`}
                        className="slot-icons"
                        style={{ marginLeft: "-15px" }}
                      />
                    )}

                    {awardQuantityLives ? (
                      <div className="bonus-award-container">
                        <div className="bonus-award-quantity">
                          {awardQuantityLives}
                        </div>
                        <div className="bonus-award-content">lives</div>
                      </div>
                    ) : null}

                    {prizeImage ? (
                      <div className="bonus-award-container">
                        <div className="bonus-award-quantity">
                          <img src={prizeImage} alt="" />
                        </div>
                        <div className="bonus-award-content">{prizeText}</div>
                      </div>
                    ) : null}

                    {awardQuantityPoints ? (
                      <div className="bonus-award-container">
                        <div className="bonus-award-quantity">
                          {awardQuantityPoints}
                        </div>
                        <div className="bonus-award-content">diamonds</div>
                      </div>
                    ) : null}

                    {iconSlotRight && (
                      <img
                        src={`../img/icns/${iconSlotRight}.png`}
                        alt={`${iconSlotRight}`}
                        className="slot-icons"
                        style={{ marginRight: "-15px" }}
                      />
                    )}
                  </div>
                )}
                <div className="bonus-description">
                  {text} <span>{boldText}</span>
                </div>
                <div className="bonus-button">
                  <Button buttonType="success" {...props}>
                    {buttonText}
                  </Button>
                </div>
              </motion.div>
            </motion.div>
          </div>
        </Background>
      </div>
    </>
  );
}

export default BonusResultModal;
