import { useAppSelector } from "../../../hooks/hooks";
import {
  selectLanguage,
  selectTranslations,
} from "../../data-access/store/general/generalSlice";
import { checkTranslationKey } from "../../../utils/translation";
import "./stats-chip.scss";

export enum StatsChipsEnum {
  LEVEL = "level",
  POINTS = "points",
  COINS = "coins",
  LIVES = "lives",
}

export interface StatsChipProps {
  type: StatsChipsEnum;
  value: number | string | undefined;
}

export function StatsChip({ type, value }: StatsChipProps) {
  const lang = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);

  const iconChip = {
    [StatsChipsEnum.LEVEL]: <img src="../img/icns/level.svg" alt="" />,
    [StatsChipsEnum.POINTS]: <img src="../img/icns/diamonds.svg" alt="" />,
    [StatsChipsEnum.COINS]: <img src="../img/icns/coin.svg" alt="" />,
    [StatsChipsEnum.LIVES]: <img src="../img/icns/lives.svg" alt="" />,
  };

  const icon = iconChip[type];

  return (
    <div
      className={`stats-chip chip-${type} ${
        // eslint-disable-next-line eqeqeq
        type === StatsChipsEnum.COINS && value == 0 ? "chip-0" : ""
      }`}
    >
      {icon}
      <div className="info">
        <p className={type}>{value}</p>
        {type === StatsChipsEnum.LEVEL ? (
          <span>{checkTranslationKey(translations[lang]?.Level, "Level")}</span>
        ) : null}
      </div>
    </div>
  );
}
