import type { CommonAnswersType } from "../gameplay-answer";
import "./gameplay-text.scss";
import cx from "classnames";
import { motion, Variants } from "framer-motion";

export type GameplayAnswerTextProps = CommonAnswersType & {
  gameplay: "text";
  options: {
    text: string;
    badgeType: "inner" | "outer";
  };
};

const bounceVariants: Variants = {
  hidden: {
    opacity: 0,
    scale: 0,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.3,
      delay: 0.3,
      scale: {
        type: "spring",
        bounce: 0.7,
      },
    },
  },
};

export function GameplayAnswerText({
  status = "default",
  options: { text, badgeType },
  blur,
  ...props
}: GameplayAnswerTextProps) {
  return (
    <button
      {...props}
      className={cx("gameplay-text", props.className, {
        correct: status === "correct",
        wrong: status === "wrong",
      })}
    >
      <div className="answer-grid-input">
        <div className={`overlay-input ${badgeType}`}>
          <div className="slot slot-start">
            {status !== "default" && props.player === "both" ? (
              <img
                src={`../img/icns/answer-${status}.svg`}
                className="icon-input"
                alt={status}
              />
            ) : null}
          </div>
          <div className={`slot slot-text ${blur ? "blur" : ""}`}>{text}</div>
          <div className={`slot slot-end ${props.player ?? ""}`}>
            {status !== "default" ? (
              badgeType === "inner" ? (
                <img
                  src={`../img/icns/answer-${status}-icon.svg`}
                  className="icon-input"
                  alt={status}
                />
              ) : (
                props.player && (
                  <motion.img
                    src={`../img/icns/answer-${status}.svg`}
                    className="icon-input"
                    alt={status}
                    variants={bounceVariants}
                    initial="hidden"
                    animate="visible"
                  />
                )
              )
            ) : null}
          </div>
        </div>
      </div>
    </button>
  );
}

export default GameplayAnswerText;
