import { createApi } from "@reduxjs/toolkit/dist/query/react";
import baseQuery from "../../../../shared/data-access/store/baseQuery";
import { AchievementsResponse } from "../../../../shared/interfaces/general.types";

export const badgesApi = createApi({
  reducerPath: "badgesApi",
  baseQuery,
  endpoints: (builder) => ({
    getAchievements: builder.query<AchievementsResponse, void>({
      query: () => "repository/achievements",
    }),
  }),
});

export const { useGetAchievementsQuery } = badgesApi;
