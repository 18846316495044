import { configureStore } from "@reduxjs/toolkit";
import uiReducer from "./ui/uiSlice";
import { authApi } from "../../../auth/data-access/store/services/auth.service";
import authReducer from "../../../auth/data-access/store/authSlice";
import messageReducer from "./message/messageSlice";
import { generalApi } from "./general/services/general.service";
import generalReducer from "./general/generalSlice";
import gameReducer from "./game/gameSlices";
import { badgesApi } from "../../../badges/data-access/store/services/badges.service";
import badgesReducer from "../../../badges/data-access/store/badgesSlice";
import { prizesApi } from "../../../prizes/data-access/store/services/prizes.service";
import prizesReducer from "../../../prizes/data-access/store/prizesSlice";
import { storeApi } from "../../../store/data-access/store/services/store.service";
import { onboardingApi } from "../../../onboarding/data-access/store/services/onboarding.service";
import { faqApi } from "../../../faq/data-access/faq.service";
import localsReducer from "./locals/localsSlice";
import { notificationsMiddleware } from "./middleware/notifications-middleware";

export const store = configureStore({
  reducer: {
    ui: uiReducer,
    locals: localsReducer,
    message: messageReducer,
    [authApi.reducerPath]: authApi.reducer,
    auth: authReducer,
    [generalApi.reducerPath]: generalApi.reducer,
    general: generalReducer,
    game: gameReducer,
    [prizesApi.reducerPath]: prizesApi.reducer,
    prizes: prizesReducer,
    [badgesApi.reducerPath]: badgesApi.reducer,
    badges: badgesReducer,
    [storeApi.reducerPath]: storeApi.reducer,
    [onboardingApi.reducerPath]: onboardingApi.reducer,
    [faqApi.reducerPath]: faqApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      generalApi.middleware,
      prizesApi.middleware,
      badgesApi.middleware,
      storeApi.middleware,
      onboardingApi.middleware,
      notificationsMiddleware
    ),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
