import { Button } from "../../../shared/ui/button";
import { Title } from "../../../shared/ui/title";
import { Auth } from "../auth/auth";
import { Modal } from "../../../shared/ui/modal";
import cx from "classnames";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";

import "./forgot-password.scss";
import { useRemindPasswordMutation } from "../../data-access/store/services/auth.service";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { setMessage } from "../../../shared/data-access/store/message/messageSlice";
import { SmsSent } from "../../../shared/ui/modal-notifications/sms-sent";
import {
  selectInit,
  selectLanguage,
  selectTranslations,
} from "../../../shared/data-access/store/general/generalSlice";
import { UpstreamServiceBanner } from "../../../shared/ui/upstream-service-banner";
import { checkTranslationKey } from "../../../utils/translation";

export function ForgotPassword() {
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);
  const [
    remindPass,
    {
      isLoading: remindPassIsLoading,
      error: remindPassError,
      isSuccess: remindPassIsSuccess,
    },
  ] = useRemindPasswordMutation();
  const init = useAppSelector(selectInit);
  const lang = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);

  useEffect(() => {
    if (remindPassError) {
      console.log(remindPassError);
      const errorMsg =
        (remindPassError as { data?: Error })?.data?.message ||
        checkTranslationKey(translations[lang]?.Error, "Error!");
      if (errorMsg) {
        dispatch(setMessage({ content: errorMsg, type: "error" }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remindPassError]);

  useEffect(() => {
    if (remindPassIsSuccess) {
      setShowModal(true);
    }
  }, [remindPassIsSuccess]);

  const prefix = init?.users?.msisdn?.prefix || "";
  const phoneMaxLength = init?.users?.msisdn?.maxLength || 9;
  const phoneMinLength = init?.users?.msisdn?.minLength || 9;

  const SignInSchema = Yup.object().shape({
    phone: Yup.string()
      .matches(
        /^[0-9]+$/,
        checkTranslationKey(
          translations[lang]?.The_phone_number_must_be_only_digits,
          "The phone number must be only digits"
        )
      )
      .min(
        phoneMinLength,
        checkTranslationKey(
          translations[lang]?.The_phone_number_is_too_short,
          "The phone number is too short."
        )
      )
      .max(
        phoneMaxLength,
        checkTranslationKey(
          translations[lang]?.The_phone_number_is_too_long,
          "The phone number is too long."
        )
      )
      .required(
        checkTranslationKey(
          translations[lang]?.The_phone_number_is_required,
          "The phone number is required."
        )
      ),
  });

  return (
    <div className="auth-page">
      <Auth
        className="auth-shell-content"
        header={
          <Title backURL="/auth/login" small>
            {checkTranslationKey(
              translations[lang]?.Forgot_Password,
              "Forgot Password"
            )}
          </Title>
        }
      >
        <div className="form-title">
          {checkTranslationKey(translations[lang]?.Don_t_worry, "Don't worry!")}
        </div>
        <div className="notice">
          <span>
            {checkTranslationKey(
              translations[lang]?.We_help_you_recover_your_password,
              "We help you recover your password"
            )}
          </span>
        </div>
        <div className="form-container">
          <Formik
            initialValues={{ phone: "" }}
            validationSchema={SignInSchema}
            onSubmit={(values) => {
              remindPass({ msisdn: prefix + values.phone });
            }}
          >
            {({ errors, touched, values }) => (
              <>
                <Form>
                  <div
                    className={cx("form-input-row", {
                      danger: errors.phone && touched.phone,
                      success: !errors.phone,
                    })}
                  >
                    <div className="prefix-input">
                      <span className="prefix">+{prefix}</span>
                      <Field
                        type="tel"
                        name="phone"
                        className="half-input"
                        placeholder={checkTranslationKey(
                          translations[lang]?.Your_phone_number,
                          "Your phone number"
                        )}
                        autoComplete="off"
                        maxLength={phoneMaxLength}
                        required
                      />
                    </div>
                    <ErrorMessage name="phone">
                      {(msg) => (
                        <div className="error-msg">
                          <img
                            src="/img/icns/alert-fill.svg"
                            alt="alert"
                            className="color-svg"
                          />
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>

                  <div className="submit">
                    <Button
                      buttonType="success"
                      type="submit"
                      value="Submit"
                      disabled={remindPassIsLoading}
                    >
                      {remindPassIsLoading
                        ? checkTranslationKey(
                            translations[lang]?.Sending_SMS,
                            "Sending SMS..."
                          )
                        : checkTranslationKey(
                            translations[lang]?.Send_SMS,
                            "Send SMS"
                          )}
                    </Button>
                  </div>
                </Form>

                <Modal show={showModal} setShow={setShowModal} hideCloseButton>
                  <SmsSent
                    onButtonClick={() => setShowModal(false)}
                    buttonText={checkTranslationKey(
                      translations[lang]?.OK,
                      "OK"
                    )}
                    phone={`+${prefix} ${values.phone}`}
                    instructions={checkTranslationKey(
                      translations[lang]
                        ?.We_have_sent_you_an_SMS_with_instructions_to_this_number,
                      "We have sent you an SMS with instructions to this number:"
                    )}
                  />
                </Modal>
              </>
            )}
          </Formik>
        </div>
      </Auth>

      <div className="stream-service">
        <UpstreamServiceBanner />
      </div>
    </div>
  );
}
