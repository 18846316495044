import { useNavigate } from "react-router-dom";
import { Button } from "../../shared/ui/button";
import { Background } from "../../shared/ui/background";
import { NavBar } from "../../shared/ui/navbar";
import { checkTranslationKey } from "../../utils/translation";
import { Title } from "../../shared/ui/title";
import { useAppSelector } from "../../hooks/hooks";
import {
  selectLanguage,
  selectTranslations,
} from "../../shared/data-access/store/general/generalSlice";
import "./404.scss";
import { motion, Variants } from "framer-motion";

const buttonVariants: Variants = {
  hidden: {
    scale: 0,
    opacity: 0,
  },
  visible: {
    scale: 1,
    opacity: 1,
    transition: {
      duration: 0.3,
      delay: 0.5,
    },
  },
};

const FourZeroFour = () => {
  const navigate = useNavigate();
  const lang = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);

  return (
    <div>
      <Background type="home">
        <NavBar hasAvatar />
        <div className="error-page-content">
          <Title>
            {checkTranslationKey(
              translations[lang]?.Page_Not_Found,
              "404: Page Not Found"
            )}
          </Title>
          <motion.div
            className="btn-wrapper"
            variants={buttonVariants}
            initial="hidden"
            animate="visible"
          >
            <Button onClick={() => navigate("/")} buttonType="success">
              {checkTranslationKey(
                translations[lang]?.Go_to_Home,
                "Go to Home"
              )}
            </Button>
          </motion.div>
        </div>
      </Background>
    </div>
  );
};

export default FourZeroFour;
