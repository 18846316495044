import { useEffect, useState } from "react";
// import { useVisibilityChange } from "./use-visiblity-change";
import {
  addSeconds,
  differenceInSeconds,
  secondsToMilliseconds,
} from "date-fns";
import useLocals from "./use-locals";

export const useCountdown = (
  durationInSeconds: number | undefined = undefined
) => {
  const { setValue, getValue } = useLocals();
  const [timeLeft, setTimeLeft] = useState(durationInSeconds);
  const [isRunning, setIsRunning] = useState(false);
  const [untilDateTime, setUntilDateTime] = useState<Date>();

  useEffect(() => {
    console.log({ durationInSeconds });
    if (durationInSeconds) {
      setUntilDateTime(addSeconds(new Date(), durationInSeconds));
    }
  }, [durationInSeconds]);

  // const visibile = useVisibilityChange();

  useEffect(() => {
    if (untilDateTime) {
      if (!getValue("timerExpTs")) {
        setValue("timerExpTs", untilDateTime.toISOString());
      }
    }
  }, [untilDateTime]);

  useEffect(() => {
    const timer = setInterval(() => {
      const expirationIso = getValue("timerExpTs");
      if (isRunning && expirationIso) {
        setTimeLeft(differenceInSeconds(new Date(expirationIso), new Date()));
      }
    }, secondsToMilliseconds(1));
    return () => clearInterval(timer);
  }, [durationInSeconds, isRunning]);

  const start = () => {
    setIsRunning(true);
  };

  const stop = () => {
    setIsRunning(false);
  };

  const reload = () => {
    stop();
    clean();
    if (durationInSeconds) {
      setUntilDateTime(addSeconds(new Date(), durationInSeconds));
    }
    start();
  };

  const clean = () => {
    setValue("timerExpTs", null);
  };

  const add = (seconds: number) => {
    const expIso = getValue("timerExpTs");
    if (expIso) {
      stop();
      clean();
      setUntilDateTime(addSeconds(new Date(expIso), seconds));
      start();
    }
  };

  const getUntilDateTime = () => {
    const expIso = getValue("timerExpTs");
    return expIso ? new Date(expIso) : null;
  };

  return {
    timeLeft,
    getUntilDateTime,
    start,
    isRunning,
    stop,
    clean,
    reload,
    add,
  };
};
