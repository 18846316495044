import React from "react";

import "./loading-opponent.scss";

const LoadingOpponent = () => {
  return (
    <div className="searching-opponent">
      <div className="spinner"></div>
      <img src="/img/prize/question-mark.png" alt="" />
    </div>
  );
};

export default LoadingOpponent;
