import { getBorderLevel } from "../../../utils/avatarBorder";
import "./avatar-border.scss";

interface AvatarBorderProps {
  level: number;
}

export function AvatarBorder({ level }: AvatarBorderProps) {
  const borderLevel = getBorderLevel(level);

  return borderLevel ? (
    <div className="avatar-border">
      <img
        src={`/img/avatars-borders/AvatarBorder-lv${borderLevel}.svg`}
        alt=""
      />
    </div>
  ) : null;
}

export default AvatarBorder;
