import { Background } from "../../shared/ui/background";
import { UpstreamServiceBanner } from "../../shared/ui/upstream-service-banner";
import { Button } from "../../shared/ui/button";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperClass } from "swiper/types";
import { Pagination, EffectFade } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "./onboarding.scss";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import {
  selectLanguage,
  selectTranslations,
} from "../../shared/data-access/store/general/generalSlice";
import { useGetOnboardingQuery } from "../data-access/store/services/onboarding.service";
import { useNavigate } from "react-router-dom";
import { checkTranslationKey } from "../../utils/translation";
import { Tutorial } from "../../faq/data-access/faq.service";
import { updateIsFirstSignIn } from "../../auth/data-access/store/authSlice";
import useAmbientSound from "../../utils/hooks/use-ambient-sound";

export function Onboarding() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [swiperController, setSwiperController] = useState<SwiperClass>();
  const [slideState, setSlideState] = useState<{
    slidesCount: number;
    activeSlide: number;
  }>({ slidesCount: 0, activeSlide: 0 });
  const { data: onboardingData } = useGetOnboardingQuery();
  const lang = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);
  useAmbientSound("tutorial");

  const lastSlide =
    (slideState?.activeSlide || 0) + 1 === slideState?.slidesCount;

  useEffect(() => {
    if (!swiperController) {
      return;
    }
    function onSlideChange() {
      setSlideState({
        slidesCount: swiperController?.slides?.length || 0,
        activeSlide: swiperController?.activeIndex || 0,
      });
    }
    swiperController.on("slideChange", onSlideChange);
    onSlideChange();
    return () => {
      swiperController.off("slideChange", onSlideChange);
    };
  }, [swiperController]);

  const handleSkip = () => {
    dispatch(updateIsFirstSignIn(false));
    navigate("/");
  };

  const renderOnboardingItems = (onboardingItems: Tutorial["items"]) => {
    return onboardingItems.map((onboardingItem) => {
      return (
        <SwiperSlide key={`onboarding-item-${onboardingItem.order}`}>
          <div className="slide-container">
            <div className="image-container">
              <span className="image-background"></span>
              <img
                src={onboardingItem.image}
                alt={checkTranslationKey(
                  translations[lang]?.Onboarding,
                  "Onboarding"
                )}
              />
            </div>
            <div className="text-container">
              <h3>{onboardingItem.title[lang]}</h3>
            </div>
          </div>
        </SwiperSlide>
      );
    });
  };

  // @ts-ignore
  return (
    <div id="onboarding">
      <Background type="onboarding">
        <div className="header-transparent">
          <img
            src="/img/batteup-logo.png"
            alt={checkTranslationKey(
              translations[lang]?.BattleUp_Logo,
              "BattleUp Logo"
            )}
            className="logo"
          />
        </div>

        <div className="onboarding-container">
          <div className="slides">
            <Swiper
              pagination={{ clickable: true }}
              fadeEffect={{
                crossFade: true,
              }}
              modules={[Pagination, EffectFade]}
              effect={"fade"}
              slidesPerView={1}
              onSwiper={(swiper) => setSwiperController(swiper)}
            >
              {onboardingData && onboardingData.items
                ? renderOnboardingItems(onboardingData.items)
                : null}
            </Swiper>
          </div>

          <div className="buttons">
            <Button
              buttonType={lastSlide ? "success-play" : "primary"}
              onClick={() =>
                lastSlide ? handleSkip() : swiperController?.slideNext()
              }
            >
              {lastSlide
                ? checkTranslationKey(translations[lang]?.Play_now, "Play now!")
                : checkTranslationKey(translations[lang]?.Next, "Next!")}
            </Button>
            {!lastSlide ? (
              <Button buttonType="transparent" onClick={handleSkip}>
                {checkTranslationKey(
                  translations[lang]?.Skip_and_play,
                  "Skip and play"
                )}
              </Button>
            ) : null}
          </div>
        </div>
      </Background>
      <div className="service-banner">
        <UpstreamServiceBanner />
      </div>
    </div>
  );
}

export default Onboarding;
