import { ComponentProps, useState } from "react";
import "./achievement.scss";
import cx from "classnames";
import { Modal } from "../../../shared/ui/modal";
import { BadgeModal } from "../achievement-modal";
import { useAppSelector } from "../../../hooks/hooks";
import {
  selectLanguage,
  selectTranslations,
} from "../../../shared/data-access/store/general/generalSlice";
import { checkTranslationKey } from "../../../utils/translation";

export interface AchievementBadgeProps extends ComponentProps<"div"> {
  badge: string;
  subtitle: string;
  description: string;
  children: string;
  badgeType: "locked" | "unlocked";
}

export function AchievementBadge({
  children,
  badge,
  subtitle,
  description,
  badgeType = "unlocked",
  className,
  ...props
}: AchievementBadgeProps) {
  const [showModal, setShowModal] = useState(false);
  const lang = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);
  return (
    <>
      <div className={cx("badge-card", className, badgeType)} {...props}>
        <div className="badge-icon" onClick={() => setShowModal(true)}>
          <img src={badge} height="80px" alt="" />
        </div>
        <div className="badge-title">{children}</div>
        {badgeType === "unlocked" ? (
          <div className="badge-subtitle">{subtitle}</div>
        ) : (
          <img src={`../img/icns/lock-2.svg`} height="30px" alt="" />
        )}
      </div>
      <Modal show={showModal} setShow={setShowModal} hideCloseButton>
        <BadgeModal
          badge={badge}
          title={children}
          buttonText={checkTranslationKey(translations[lang]?.OK, "OK")}
          buttonClick={() => setShowModal(false)}
        >
          {description}
        </BadgeModal>
      </Modal>
    </>
  );
}

export default AchievementBadge;
