import { createApi } from "@reduxjs/toolkit/dist/query/react";
import baseQuery from "../../shared/data-access/store/baseQuery";

export type Tutorial = {
  items: Array<{
    title: {
      [k: string]: string;
    };
    image: string;
    description: {
      [k: string]: string;
    };
    order: number;
  }>;
};

export const faqApi = createApi({
  reducerPath: "faqApi",
  baseQuery,
  endpoints: (builder) => ({
    getTutorial: builder.query<Tutorial, void>({
      query: () => "repository/tutorial",
    }),
  }),
});

export const { useGetTutorialQuery } = faqApi;
