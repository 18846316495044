import type { Socket } from "socket.io-client";
import { Last } from "../../../../../utils/typescript/arrays/last";

export type SocketOnParams = Parameters<Socket["on"]>;
export type SocketOffParams = Parameters<Socket["off"]>;
/**
 * @description This ListenerService is a singleton that will be used to listen to events from the socket
 * This will allow us to manage and make sure that we don't have multiple listeners for the same event
 */
export class ListenerService<EventType extends string> {
  private static instanceMap = new Map<string, ListenerService<string>>();

  private listeners = new Map<string, Last<SocketOnParams>>();

  get size() {
    return this.listeners.size;
  }
  constructor(private event: EventType) {
    if (ListenerService.instanceMap.has(event)) {
      return ListenerService.instanceMap.get(
        this.event
      )! as ListenerService<EventType>;
    }
    ListenerService.instanceMap.set(event, this);
  }

  public on(callback: Last<SocketOnParams>) {
    // generate random string
    const random = Math.random().toString(36).substring(7);
    const id: `${EventType}-${string}` = `${this.event}-${random}`;
    this.listeners.set(id, callback);
    return id;
  }

  public off(id: string): number {
    if (this.listeners.has(id)) {
      this.listeners.delete(id);
    }
    return this.size;
  }

  public sendToListeners(...data: Parameters<Last<SocketOnParams>>) {
    this.listeners.forEach((listener) => listener(...data));
  }
}
