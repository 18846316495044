const levelMilestones = [3, 9, 15, 21, 27, 33, 39, 45, 51, 56];

export const getBorderLevel = (userLevel: number): number => {
  for (let i = levelMilestones.length - 1; i >= 0; i--) {
    if (userLevel >= levelMilestones[i]) {
      return levelMilestones[i];
    }
  }
  return 0; // Default case if level is less than the first milestone
};

export const nextLevel = (userLevel: number): number => {
  for (let i = 0; i < levelMilestones.length; i++) {
    if (userLevel < levelMilestones[i]) {
      return levelMilestones[i];
    }
  }
  return 0; // Default case if level is greater than the last milestone
};
