export const pageTitleFromPath = (path: string) => {
  let pageTitle = "";
  if (path === "/auth") {
    pageTitle = "Sign in";
  } else if (path === "/auth/sign-up") {
    pageTitle = "Sign up";
  } else if (path === "/auth/forgot-password") {
    pageTitle = "Forgot password";
  }
  return pageTitle;
};

export const siteSectionByPath = (path: string) => {
  let siteSection = "landing";
  if (path.includes("/auth")) {
    siteSection = "auth";
  } else if (path.includes("/profile")) {
    siteSection = "account";
  } else if (
    path.includes("/gameplay") ||
    path.includes("/opponent") ||
    path.includes("/ladder") ||
    path.includes("/category")
  ) {
    siteSection = "game";
  } else if (path.includes("/onboarding")) {
    siteSection = "onboarding";
  } else if (path.includes("/leaderboard")) {
    siteSection = "leaderboard";
  } else if (path.includes("/prizes")) {
    siteSection = "prizes";
  } else if (path.includes("/achievements")) {
    siteSection = "achievements";
  } else if (path.includes("/faq")) {
    siteSection = "faq";
  } else if (path.includes("/results")) {
    siteSection = "results";
  }
  return siteSection;
};

export const pageCategoryByPath = (path: string) => {
  let siteCategory = "/";
  if (path.includes("/auth")) {
    siteCategory = "/auth";
  } else if (path.includes("/profile")) {
    siteCategory = "/profile";
  } else if (path.includes("/gameplay")) {
    siteCategory = "/gameplay";
  } else if (path.includes("/opponent")) {
    siteCategory = "/opponent";
  } else if (path.includes("/ladder")) {
    siteCategory = "/ladder";
  } else if (path.includes("/category")) {
    siteCategory = "/category";
  } else if (path.includes("/onboarding")) {
    siteCategory = "/onboarding";
  } else if (path.includes("/leaderboard")) {
    siteCategory = "/leaderboard";
  } else if (path.includes("/prizes")) {
    siteCategory = "/prizes";
  } else if (path.includes("/achievements")) {
    siteCategory = "/achievements";
  } else if (path.includes("/faq")) {
    siteCategory = "/faq";
  } else if (path.includes("/results")) {
    siteCategory = "/results";
  }
  return siteCategory;
};
