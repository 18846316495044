import {
  Routes,
  Route,
  useSearchParams,
  useLocation,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { useEffect, useRef } from "react";
import { toast } from "react-toastify";

import "./App.scss";
import "react-toastify/dist/ReactToastify.css";

import Onboarding from "./onboarding/feature/onboarding";
import Home from "./home/feature/home";
import { OpponentShell } from "./opponent/feature/opponent-shell";

import { Ladder } from "./ladder/feature";

import { useAppDispatch, useAppSelector } from "./hooks/hooks";
import {
  clearMessage,
  selectMessage,
  setMessage,
} from "./shared/data-access/store/message/messageSlice";
import { selectToken } from "./auth/data-access/store/authSlice";
import {
  useGetTranslationsQuery,
  useInitQuery,
} from "./shared/data-access/store/general/services/general.service";
import {
  useEventMutation,
  useLazyGetUserQuery,
  useLoginWithTokenMutation,
} from "./auth/data-access/store/services/auth.service";
import { AuthShell } from "./auth/feature/shell";
import FourZeroFour from "./404/ui/404";
import { GameplayShell } from "./gameplay/feature/gameplay-shell";
import { ProfileShell } from "./profile/feature/profile-shell";
import { StoreShell } from "./store/feature/store-shell";
import { LeaderboardShell } from "./leaderboard/feature/leaderboard-shell";
import { PrizesShell } from "./prizes/feature/prizes-shell";
import { BadgesShell } from "./badges/feature/badges-shell";
import { FaqShell } from "./faq/feature/faq-shell";
import { CategoryShell } from "./category/feature/category-shell";
import Results from "./results/feature/results";
import { useGame } from "./shared/data-access/store/game/hooks/use-game";
// import RouteLoaded from "./shared/data-access/route-loaded";
import Notifications from "./notifications/feature/notifications";
import PrivateRoute from "./shared/guards/PrivateRoute";
import { Loader } from "./shared/ui/loader";
import useLocals from "./utils/hooks/use-locals";
import { addSeconds, differenceInMilliseconds } from "date-fns";
import {
  selectIsLoadingPage,
  selectTutorial,
  setIsLoadingPage,
  setTutorial,
} from "./shared/data-access/store/ui/uiSlice";
import { useSelector } from "react-redux";
import SoundManager from "./utils/managers/sound-manager";
import { useAudioUnlocker } from "./utils/hooks/use-audio-unlocker";
import Joyride, {
  ACTIONS,
  CallBackProps,
  EVENTS,
  ORIGIN,
  STATUS,
} from "react-joyride";
import ModalSteps from "./shared/ui/modal-steps/modal-steps";
import useMiniTutorials from "./utils/hooks/use-mini-tutorials";
import ReactGA from "react-ga4";
import usePageTracking from "./utils/hooks/use-page-tracking";

/**
 * This is where all the routing paths are set up.
 * @category Router
 */

const SECONDS_PER_QUESTION = 30;
const NUMBER_OF_ROUNDS = 10;
const INDULGENCE = 3;
export const gameRoutes = ["opponent", "ladder", "gameplay", "category"];

const TRACKING_ID_1 = "G-96YT2KWBBF";
ReactGA.initialize([
  {
    trackingId: TRACKING_ID_1,
  },
]);

function App() {
  const dispatch = useAppDispatch();
  const message = useAppSelector(selectMessage);
  const { isLoading: initIsLoading } = useInitQuery();
  const { getValue, setValue, reset: resetLocals } = useLocals();
  const [searchParams] = useSearchParams();
  const authToken = searchParams.get("authToken");
  const [
    loginWithToken,
    { isLoading: loginWithTokenIsLoading, error: loginWithTokenError },
  ] = useLoginWithTokenMutation();
  const [getUser, { isLoading: userIsLoading }] = useLazyGetUserQuery();
  const token = useAppSelector(selectToken);
  const { isLoading: translationsIsLoading } = useGetTranslationsQuery();
  const isLoadingPage = useSelector(selectIsLoadingPage);
  const [event] = useEventMutation();
  const tutorial = useAppSelector(selectTutorial);
  const { data: dataMiniTutorials } = useMiniTutorials();
  const steps = getStepsByType(tutorial.show) || [];

  useAudioUnlocker();
  usePageTracking();

  if (SoundManager.ambientSounds.home.paused) {
    SoundManager.ambientSounds.home.currentTime = 0;
  }

  useEffect(() => {
    event({ type: "APP_LAUNCH" });
    const tokenForLogin = localStorage.getItem("tokenForLogin");
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken && (authToken || tokenForLogin)) {
      const tokenToSend = (authToken || tokenForLogin) as string;
      loginWithToken({ token: tokenToSend });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (message && message.content) {
      if (message.type === "error") {
        toast.error(message.content, {
          onClose: () => dispatch(clearMessage()),
        });
      } else if (message.type === "success") {
        toast.success(message.content, {
          onClose: () => dispatch(clearMessage()),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  useEffect(() => {
    if (token) {
      getUser();
    }
  }, [token]);

  useEffect(() => {
    if (loginWithTokenError) {
      // @ts-ignore
      const errorMsg = loginWithTokenError?.data?.message || "Error!";
      if (errorMsg) {
        dispatch(setMessage({ content: errorMsg, type: "error" }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginWithTokenError]);

  const { send, gameState, finish, resetGame } = useGame();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const pathnameRef = useRef(pathname);

  useEffect(() => {
    pathnameRef.current = pathname;
  }, [pathname]);

  useEffect(() => {
    if (pathname.includes("bonus")) {
      return;
    }

    if (gameRoutes.some((keyword) => pathname.includes(keyword))) {
      if (!gameState.game?.id) {
        send(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const onExpiration = async () => {
    resetLocals();
    dispatch(setIsLoadingPage(true));
    await finish(true);
    resetGame();

    if (pathnameRef.current.includes("opponent")) {
      dispatch(setIsLoadingPage(false));
      send(true);
      navigate("/opponent");
    } else {
      dispatch(
        setMessage({
          type: "error",
          content: "Last game expired, try again",
        })
      );
      dispatch(setIsLoadingPage(false));
      navigate("/");
    }
  };

  const handleFinishedGame = async () => {
    resetLocals();
    dispatch(setIsLoadingPage(true));
    await finish(true);
    resetGame();
    dispatch(setIsLoadingPage(false));
    send(true);
    navigate("/opponent");
  };

  useEffect(() => {
    if (gameState.game?.id) {
      if (!gameState.game?.finished) {
        getUser();
        if (gameState.game.id !== getValue("currentGameId")) {
          if (
            getValue("triggerNewGame") &&
            gameState.game.current_round_index > 0
          ) {
            handleFinishedGame();
          } else {
            resetLocals();
            setValue("currentGameId", gameState.game.id);

            const expiration = addSeconds(
              new Date(),
              SECONDS_PER_QUESTION * NUMBER_OF_ROUNDS * INDULGENCE
            ).toISOString();

            setValue("currentGameExpTs", expiration);

            const timeout = setTimeout(() => {
              onExpiration();
            }, differenceInMilliseconds(new Date(expiration), new Date()));
            return () => {
              clearTimeout(timeout);
            };
          }
        } else {
          if (getValue("triggerNewGame")) {
            handleFinishedGame();
          } else {
            const expLocals = getValue("currentGameExpTs");

            if (expLocals) {
              if (new Date(expLocals) <= new Date()) {
                onExpiration();
              } else {
                const timeout = setTimeout(() => {
                  onExpiration();
                }, differenceInMilliseconds(new Date(expLocals), new Date()));
                return () => {
                  clearTimeout(timeout);
                };
              }
            }
          }
        }
      } else {
        handleFinishedGame();
      }
    }
  }, [gameState.game?.id]);

  const handleJoyrideCallback = (data: CallBackProps) => {
    // eslint-disable-next-line no-console
    // console.log("Joyride data", data);
    const { action, index, origin, status, type } = data;

    if (action === ACTIONS.CLOSE && origin === ORIGIN.KEYBOARD) {
      // do something
    }

    // @ts-ignore
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      dispatch(
        setTutorial({
          show: tutorial.show,
          step: index + (action === ACTIONS.PREV ? -1 : 1),
        })
      );
    } else {
      // @ts-ignore
      if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
        // Need to set our running state to false, so we can restart if we click start again.
        dispatch(
          setTutorial({
            show: false,
            step: 0,
          })
        );
      }
    }
  };

  function getStepsByType(type: typeof tutorial.show) {
    switch (type) {
      case "home":
        return dataMiniTutorials?.stepsHome;
      case "ladder":
        return dataMiniTutorials?.stepsLadder;
      case "gameplay":
        return dataMiniTutorials?.stepsGame;
      default:
        return [];
    }
  }

  if (
    initIsLoading ||
    loginWithTokenIsLoading ||
    userIsLoading ||
    translationsIsLoading ||
    isLoadingPage
  ) {
    return <Loader />;
  }

  return (
    <>
      <Joyride
        key={tutorial.show ? tutorial.show : "none"}
        tooltipComponent={(props) => (
          <ModalSteps
            {...props}
            totalSteps={steps.length}
            handleClose={() => dispatch(setTutorial({ show: false, step: 0 }))}
          />
        )}
        steps={steps}
        run={Boolean(tutorial.show)}
        continuous={true}
        disableScrolling
        callback={handleJoyrideCallback}
        stepIndex={tutorial.step}
        styles={{
          options: {
            overlayColor: "rgba(0, 0, 0, 0.75)",
            arrowColor: "#E7E7E7",
          },
        }}
      />
      <Notifications />
      <Routes>
        <Route
          element={
            <PrivateRoute
              grantedRoles={["user", "userToken"]}
              onDenied={() => <Navigate to="/auth/sign-in" replace />}
              onIncomplete={() => <Navigate to="/auth/sign-up" replace />}
            />
          }
        >
          <Route path="/" element={<Home />} />
          <Route path="/onboarding" element={<Onboarding />} />
          <Route path="/opponent/*" element={<OpponentShell />} />
          <Route path="/ladder" element={<Ladder />} />
          <Route path="/category/*" element={<CategoryShell />} />
          <Route path="/gameplay/*" element={<GameplayShell />} />
          <Route path="/profile/*" element={<ProfileShell />} />
          <Route path="/store/*" element={<StoreShell />} />
          <Route path="/leaderboard/*" element={<LeaderboardShell />} />
          <Route path="/prizes/*" element={<PrizesShell />} />
          <Route path="/achievements/*" element={<BadgesShell />} />
          <Route path="/faq/*" element={<FaqShell />} />
          <Route path="/results" element={<Results />} />
        </Route>

        <Route path="/auth/*" element={<AuthShell />} />

        <Route path="*" element={<FourZeroFour />}></Route>
      </Routes>
    </>
  );
}

export default App;
