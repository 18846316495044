import { Button } from "../../../shared/ui/button";

import "./modal-promotion.scss";

export interface ModalPromotionProps {
  button: {
    text: string;
    action?: () => void;
  };
}

const ModalPromotion = ({ button }: ModalPromotionProps) => {
  const termsUrl = process.env.REACT_APP_PROMO_TERMS_URL;

  return (
    <div className={"modal-promotion"}>
      <div className="modal-content">
        <div
          className="modal-bg"
          style={{ backgroundImage: "url(../img/promotion/bg-glow.png)" }}
        ></div>

        <img src="../img/promotion/logo-glow.png" alt="" className="logo" />

        <h3 className="title">Join Glow Now!</h3>
        <p className="subtitle">Dial *126*14# to subscribe now!</p>
        <p className="description">
          <strong>First day free!</strong> After trial R5/day or a lesser amount
          <br />
          {termsUrl && (
            <a href={termsUrl} target={"_blank"}>
              <em>Terms & Conditions</em>
            </a>
          )}
        </p>

        <Button
          buttonType="transparent"
          type="button"
          onClick={button.action}
          className={"modal-button"}
        >
          {button.text}
        </Button>
      </div>
    </div>
  );
};

export default ModalPromotion;
