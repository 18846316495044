import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  localsActions,
  LocalsKeysKeys,
  LocalsState,
  selectLocals,
} from "../../shared/data-access/store/locals/localsSlice";

const useLocals = () => {
  const dispatch = useDispatch();
  const locals = useSelector(selectLocals);
  const localsRef = useRef(locals);

  useEffect(() => {
    localsRef.current = locals;
  }, [locals]);

  const setValue = (key: LocalsKeysKeys, value: LocalsState[typeof key]) => {
    dispatch(localsActions.setValue({ key, value }));
  };

  const getValue = (key: LocalsKeysKeys) => localsRef.current[key];

  const reset = () => {
    dispatch(localsActions.reset());
  };

  return {
    setValue,
    reset,
    getValue,
  };
};

export default useLocals;
