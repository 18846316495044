import { createApi } from "@reduxjs/toolkit/dist/query/react";
import baseQuery from "../../../../shared/data-access/store/baseQuery";
import {
  ConsumeNotificationsArg,
  EventArg,
  GetLeaderboardArg,
  Leaderboard,
  Login,
  LoginResponse,
  NotificationsResponse,
  PointsResponse,
  RemindPassword,
  SubscribeDirect,
  SubscribePrompt,
  User,
  UserPUT,
} from "../../../../shared/interfaces/auth.types";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery,
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, Login>({
      query: (credentials) => {
        return {
          url: "user/login",
          method: "POST",
          body: credentials,
        };
      },
      transformResponse: (response: LoginResponse) => {
        const token = response.apikey;
        localStorage.setItem("accessToken", token);
        return response;
      },
    }),
    loginWithToken: builder.mutation<LoginResponse, { token: string }>({
      query: (token) => {
        return {
          url: "user/login-via-token",
          method: "POST",
          body: token,
        };
      },
      transformResponse: (response: LoginResponse, meta, arg) => {
        const apikey = response.apikey;
        localStorage.setItem("tokenForLogin", arg.token);
        localStorage.setItem("accessToken", apikey);
        return response;
      },
    }),
    loginWithMsisdn: builder.mutation<LoginResponse, { msisdn: string }>({
      query: ({ msisdn }) => {
        return {
          url: "user/login-via-msisdn",
          method: "POST",
          body: {
            msisdn,
          },
        };
      },
      transformResponse: (response: LoginResponse) => {
        const token = response.apikey;
        localStorage.setItem("accessToken", token);
        return response;
      },
    }),
    remindPassword: builder.mutation<null, RemindPassword>({
      query: (msisdn) => {
        return {
          url: "user/remind-password",
          method: "POST",
          body: msisdn,
        };
      },
    }),
    subscribePrompt: builder.mutation<null, SubscribePrompt>({
      query: (msisdn) => {
        return {
          url: "user/subscribe-prompt",
          method: "POST",
          body: msisdn,
        };
      },
    }),
    generate: builder.mutation<null, SubscribePrompt>({
      query: (msisdn) => {
        return {
          url: "user/generate",
          method: "POST",
          body: msisdn,
        };
      },
    }),
    subscribeDirect: builder.mutation<{ apikey: string }, SubscribeDirect>({
      query: (pin) => {
        return {
          url: "user/subscribe-direct",
          method: "POST",
          body: pin,
        };
      },
    }),
    unsubscribe: builder.mutation<null, void>({
      query: () => {
        return {
          url: "user/unsubscribe",
          method: "POST",
        };
      },
    }),
    logout: builder.mutation<null, void>({
      query: (token) => {
        return {
          url: "user/logout",
          method: "POST",
          body: token,
        };
      },
    }),
    getUser: builder.query<User, void>({
      query: () => "user/info",
    }),
    putUser: builder.mutation<User, UserPUT>({
      query: (settings) => {
        return {
          url: "user/info",
          method: "PUT",
          body: settings,
        };
      },
    }),
    getUserPoints: builder.query<PointsResponse, void>({
      query: () => "user/points",
    }),
    getLeaderboard: builder.query<Leaderboard, GetLeaderboardArg>({
      query: ({ limit, offset }) => {
        return {
          url: "user/leaderboard",
          params: { limit, offset },
        };
      },
    }),
    event: builder.mutation<null, EventArg>({
      query: (type) => {
        return {
          url: "user/event",
          method: "POST",
          body: type,
        };
      },
    }),
    getNotifications: builder.query<NotificationsResponse, void>({
      query: () => "user/notifications",
    }),
    consumeNotifications: builder.mutation<
      NotificationsResponse,
      ConsumeNotificationsArg
    >({
      query: (id) => {
        return {
          url: "user/notifications/consume",
          method: "POST",
          body: id,
        };
      },
    }),
    setPromoVideoViewed: builder.mutation<void, void>({
      query: () => {
        return {
          url: "user/video/viewed",
          method: "POST",
        };
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useRemindPasswordMutation,
  useSubscribePromptMutation,
  useGenerateMutation,
  useSubscribeDirectMutation,
  useUnsubscribeMutation,
  useLogoutMutation,
  useLoginWithTokenMutation,
  useLoginWithMsisdnMutation,
  useLazyGetUserQuery,
  useGetUserQuery,
  useGetUserPointsQuery,
  useGetLeaderboardQuery,
  usePutUserMutation,
  useConsumeNotificationsMutation,
  useEventMutation,
  useSetPromoVideoViewedMutation,
} = authApi;
