import { Navigate, Route, Routes } from "react-router-dom";
import { EditProfile } from "../profile-edit";
import { ViewProfile } from "../profile-view";
import useAmbientSound from "../../../utils/hooks/use-ambient-sound";

export function ProfileShell() {
  useAmbientSound("home");

  return (
    <Routes>
      <Route path="/" element={<ViewProfile />} />
      <Route path="edit" element={<EditProfile />} />
      <Route path="*" element={<Navigate to="." replace />} />
    </Routes>
  );
}

export default ProfileShell;
