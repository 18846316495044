import { Background } from "../../../shared/ui/background";
import { NavBar } from "../../../shared/ui/navbar";
import { Title } from "../../../shared/ui/title";
import AccordionItem from "../../ui/accordion-item/accordion-item";
import "./faq-view.scss";
import { useGetTutorialQuery, Tutorial } from "../../data-access/faq.service";
import { useAppSelector } from "../../../hooks/hooks";
import {
  selectLanguage,
  selectTranslations,
} from "../../../shared/data-access/store/general/generalSlice";
import { checkTranslationKey } from "../../../utils/translation";
import { motion, Variants } from "framer-motion";
import useAmbientSound from "../../../utils/hooks/use-ambient-sound";

const faqItemVariants: Variants = {
  hidden: {
    y: 20,
    opacity: 0,
  },
  visible: (custom) => ({
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.2,
      delay: 0.1 * (custom + 1),
    },
  }),
};

export function FaqView() {
  const { data: tutorialData, isLoading: tutorialIsLoading } =
    useGetTutorialQuery();
  const lang = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);
  useAmbientSound("home");

  const renderFaqItems = (faqItems: Tutorial["items"]) => {
    return faqItems.map((item, index) => {
      return (
        <motion.div
          key={`tutorial-${item.order}`}
          variants={faqItemVariants}
          initial="hidden"
          animate="visible"
          custom={index}
        >
          <AccordionItem
            title={item.title[lang]}
            description={item.description[lang]}
          />
        </motion.div>
      );
    });
  };

  if (tutorialIsLoading) {
    return null;
  }

  return (
    <div className="accordion-container">
      <Background type="home">
        <NavBar hasAvatar />
        <div className="faq-content">
          <Title backURL="/">
            {checkTranslationKey(translations[lang]?.FAQ, "FAQ")}
          </Title>
          <ul className="accordion">
            {tutorialData ? renderFaqItems(tutorialData.items) : null}
          </ul>
        </div>
      </Background>
    </div>
  );
}

export default FaqView;
