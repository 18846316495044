import { useEffect, useState } from "react";
import { Auth } from "../auth/auth";
import { Button } from "../../../shared/ui/button";
import { Title } from "../../../shared/ui/title";
import { UpstreamServiceBanner } from "../../../shared/ui/upstream-service-banner";
import { Modal } from "../../../shared/ui/modal";
import "./unsubscribe.scss";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { selectUser } from "../../data-access/store/authSlice";
import { formatDate } from "../../../utils/dates";
import {
  selectInit,
  selectLanguage,
  selectTranslations,
} from "../../../shared/data-access/store/general/generalSlice";
import {
  useLazyGetUserQuery,
  useUnsubscribeMutation,
} from "../../data-access/store/services/auth.service";
import { setMessage } from "../../../shared/data-access/store/message/messageSlice";
import { useNavigate } from "react-router-dom";
import { checkTranslationKey } from "../../../utils/translation";

export function Unsubscribe() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const init = useAppSelector(selectInit);
  const user = useAppSelector(selectUser);
  const [getUser] = useLazyGetUserQuery();
  const [showModalText, setShowModalText] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const unsubscribeMethod = init?.users?.unsubscribeMethod;
  const [
    unsubscribe,
    { error: unsubscribeError, isSuccess: unsubscribeIsSuccess },
  ] = useUnsubscribeMutation();
  const lang = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);

  const expireDate = user?.eligibility?.expires
    ? formatDate(user?.eligibility?.expires)
    : "-";
  const renewalDate = user?.eligibility?.renewal
    ? formatDate(user?.eligibility?.renewal)
    : "-";

  useEffect(() => {
    if (unsubscribeError) {
      const errorMsg =
        (unsubscribeError as { data?: Error })?.data?.message ||
        checkTranslationKey(translations[lang]?.Error, "Error!");
      if (errorMsg) {
        dispatch(setMessage({ content: errorMsg, type: "error" }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unsubscribeError]);

  useEffect(() => {
    if (unsubscribeIsSuccess) {
      getUser();
      dispatch(
        setMessage({
          content: checkTranslationKey(
            translations[lang]?.You_have_been_unsubscribed,
            "You have been unsubscribed"
          ),
          type: "success",
        })
      );
      navigate("/profile");
    }
  }, [unsubscribeIsSuccess, dispatch, navigate, getUser, translations, lang]);

  const handleUnsubscribe = () => {
    setShowModal(false);
    unsubscribe();
  };

  const isSubscribed = user?.eligibility?.subscriptionStatus === "ACTIVE";

  return (
    <div id="unsubscribe">
      <Auth
        className="auth-shell-content"
        header={
          <Title backURL="/profile">
            {checkTranslationKey(
              translations[lang]?.Unsubscribe,
              "Unsubscribe"
            )}
          </Title>
        }
        hasFooter={false}
      >
        <div className="subscription-info-item">
          <p>{checkTranslationKey(translations[lang]?.Active, "Active")}</p>
          <span>
            {isSubscribed ? (
              <img
                src="/img/icns/answer-correct.svg"
                className="icon-input"
                alt=""
              />
            ) : (
              <img
                src="/img/icns/answer-wrong.svg"
                className="icon-input"
                alt=""
              />
            )}
          </span>
        </div>
        <div className="subscription-info-item">
          <p>
            {checkTranslationKey(
              translations[lang]?.Eligible_until,
              "Eligible until"
            )}
          </p>
          <span className="tag-item">{expireDate}</span>
        </div>
        <div className="subscription-info-item">
          <p>
            {checkTranslationKey(
              translations[lang]?.Renewal_date,
              "Renewal date"
            )}
          </p>
          <span className="tag-item">{renewalDate}</span>
        </div>
        <div className="subscription-info-item">
          <p>
            {checkTranslationKey(
              translations[lang]?.Subscription_scheme,
              "Subscription scheme"
            )}
          </p>
          <span className="tag-item">{init?.users?.subscriptionType}</span>
        </div>
        <div className="btn-container">
          <Button
            disabled={!isSubscribed}
            buttonType="success"
            type="submit"
            value="Submit"
            onClick={() =>
              unsubscribeMethod === "direct"
                ? setShowModal(true)
                : setShowModalText(true)
            }
          >
            {isSubscribed
              ? checkTranslationKey(
                  translations[lang]?.Unsubscribe,
                  "Unsubscribe"
                )
              : checkTranslationKey(
                  translations[lang]?.Already_unsubscribed,
                  "Already unsubscribed"
                )}
          </Button>
        </div>

        <Modal setShow={setShowModalText} show={showModalText} hideCloseButton>
          <div className="modal-content">
            <div className="modal-description">
              <p className="title-modal">
                {checkTranslationKey(
                  translations[lang]?.We_re_sorry_to_see_you_go,
                  "We’re sorry to see you go!"
                )}
              </p>
              <div className="description">
                <img src="/img/icns/unsubscribe.svg" alt="" />
                <p>
                  {checkTranslationKey(
                    translations[lang]
                      ?.To_cancel_your_subscription_send_and_SMS_to_4124,
                    "To cancel your subscription send and SMS to 4124"
                  )}
                </p>
              </div>
            </div>
            <div className="bottom-area">
              <Button
                onClick={() => setShowModalText(false)}
                buttonType="success"
                small
              >
                {checkTranslationKey(translations[lang]?.OK, "OK")}
              </Button>
            </div>
          </div>
        </Modal>

        <Modal setShow={setShowModal} show={showModal} hideCloseButton>
          <div className="modal-content">
            <div className="modal-description">
              <p className="title-modal">
                {checkTranslationKey(
                  translations[lang]?.We_re_sorry_to_see_you_go,
                  "We’re sorry to see you go!"
                )}
              </p>
              <div className="description">
                <img src="/img/icns/unsubscribe.svg" alt="" />
                <p>
                  {checkTranslationKey(
                    translations[lang]
                      ?.Are_you_sure_you_want_to_cancel_your_subscription,
                    "Are you sure you want to cancel your subscription?"
                  )}
                </p>
              </div>
            </div>
            <div className="bottom-area">
              <Button
                onClick={() => setShowModal(false)}
                buttonType="white"
                small
              >
                {checkTranslationKey(translations[lang]?.No, "No")}
              </Button>
              <Button onClick={handleUnsubscribe} buttonType="success" small>
                {checkTranslationKey(translations[lang]?.Yes, "Yes")}
              </Button>
            </div>
          </div>
        </Modal>
      </Auth>
      <div className="stream-service">
        <UpstreamServiceBanner />
      </div>
    </div>
  );
}
