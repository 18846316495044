import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export enum LocalsKeys {
  currentGameId = "current-game-id",
  currentGameExpTs = "current-game-expiration-timestamp",
  currentRoundExpTs = "current-round-expiration-timestamp",
  ladderExpTs = "ladder-expiration-timestamp",
  timerExpTs = "timer-expiration-timestamp",
  triggerNewGame = "trigger-new-game",
  soundsDisabled = "sounds-disabled",
}

export type LocalsKeysKeys = keyof typeof LocalsKeys;

/* explanation for generalisation below
Locals = {
  currentGameId: string | null
  currentGameExpTs: string | null
  currentRoundExpTs: string | null
}
*/

export type Locals<T extends string> = {
  [K in T]: string | null;
};

export type LocalsState = Locals<LocalsKeysKeys>;

const initialState = (Object.keys(LocalsKeys) as Array<LocalsKeysKeys>).reduce(
  (result, key) => ({ ...result, [key]: localStorage.getItem(key) }),
  {}
) as LocalsState;

const localsSlice = createSlice({
  name: "locals",
  initialState,
  reducers: {
    setValue: (
      state,
      action: PayloadAction<{
        key: LocalsKeysKeys;
        value: LocalsState[typeof key];
      }>
    ) => {
      const { key, value } = action.payload;
      state[key] = value;
      if (value) {
        localStorage.setItem(key, value);
      } else {
        localStorage.removeItem(key);
      }
    },
    reset: (state) => {
      const keys = Object.keys(state) as Array<LocalsKeysKeys>;

      keys.forEach((key) => {
        if (key !== "soundsDisabled") {
          state[key] = null;
          localStorage.removeItem(key);
        }
      });
    },
  },
});

const { reducer, actions } = localsSlice;

export const localsActions = actions;

export const selectLocals = (state: RootState) => state.locals;

export default reducer;
