import { createApi } from "@reduxjs/toolkit/dist/query/react";
import baseQuery from "../../../../shared/data-access/store/baseQuery";
import { OnboardingStepsResponse } from "../../../../shared/interfaces/general.types";

export const onboardingApi = createApi({
  reducerPath: "onboardingApi",
  baseQuery,
  endpoints: (builder) => ({
    getOnboarding: builder.query<OnboardingStepsResponse, void>({
      query: () => "repository/onboard-steps",
    }),
  }),
});

export const { useGetOnboardingQuery } = onboardingApi;
