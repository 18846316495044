import { AnimatePresence, motion, Variants } from "framer-motion";
import { Modal } from "../../modal";
import { Button } from "../../button";
import { useAppSelector } from "../../../../hooks/hooks";
import {
  selectLanguage,
  selectTranslations,
} from "../../../data-access/store/general/generalSlice";
import { checkTranslationKey } from "../../../../utils/translation";
import "./level-up.scss";
import { useEffect } from "react";
import SoundManager from "../../../../utils/managers/sound-manager";
import { fadeOutBgAudio } from "../../../../utils/fade-audio";

const levelUpContainerVariants: Variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.3,
    },
  },
};

const contentVariants: Variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.3,
      delay: 1.5,
    },
  },
};

const bgVariants: Variants = {
  hidden: {
    opacity: 0,
    scale: 0,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.3,
      delay: 0.3,
      scale: {
        type: "spring",
        bounce: 0.7,
      },
    },
  },
};

const rayVariants: Variants = {
  hidden: {
    opacity: 0,
    scale: 0,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.3,
      delay: 0.5,
    },
  },
};

const badgeVariants: Variants = {
  hidden: {
    opacity: 0,
    scale: 0,
  },
  visible: {
    opacity: 1,
    scale: [1, 1.1, 1],
    transition: {
      duration: 0.2,
      delay: 0.8,
      scale: {
        repeat: 1,
      },
    },
  },
};

export interface LevelUpProps {
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  buttonClick: () => void;
  level: string | number;
  points: string | number;
  title: string;
  subtitle: string;
  lives: number;
  livesText: string;
  nextLevelDescription: string;
  buttonText: string;
}

export function LevelUp({
  modalOpen,
  setModalOpen,
  buttonClick,
  level,
  points,
  title,
  subtitle,
  lives,
  livesText,
  nextLevelDescription,
  buttonText,
}: LevelUpProps) {
  const livesTextSplit = livesText ? livesText.split("{heart}") : "";
  const lang = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);

  useEffect(() => {
    if (modalOpen) {
      fadeOutBgAudio(
        SoundManager.ambientSounds.home,
        SoundManager.vfxSounds.level_up
      );
    }
  }, [modalOpen]);

  return (
    <AnimatePresence>
      {modalOpen && (
        <motion.div
          id="level-up"
          className={modalOpen ? "modal-open" : ""}
          variants={levelUpContainerVariants}
          initial={"hidden"}
          animate={"visible"}
          exit={"hidden"}
        >
          <Modal
            setShow={setModalOpen}
            show={modalOpen}
            defaultAnimations={false}
            hideCloseButton
            hasLights
            disableClickOutside
          >
            <>
              <motion.img
                src="../img/level-up/bg-modal.png"
                alt=""
                className="bg-modal"
                variants={bgVariants}
                initial={"hidden"}
                animate={modalOpen && "visible"}
              />
              <motion.img
                src="../img/level-up/rays-modal-top.svg"
                alt=""
                className="rays rays-top"
                variants={rayVariants}
                initial={"hidden"}
                animate={modalOpen && "visible"}
              />
              <motion.img
                src="../img/level-up/rays-modal-bottom.svg"
                alt=""
                className="rays rays-bottom"
                variants={rayVariants}
                initial={"hidden"}
                animate={modalOpen && "visible"}
              />
              <motion.div
                className="level"
                variants={badgeVariants}
                initial={"hidden"}
                animate={modalOpen && "visible"}
              >
                <img src="../img/icns/ribon.svg" alt="" />
                <p>{level}</p>
              </motion.div>
              <motion.div
                className="content"
                variants={contentVariants}
                initial={"hidden"}
                animate={"visible"}
              >
                <p className="title-modal">{title}</p>
                <div className="points">
                  <img src="../img/icns/diamonds.svg" alt="" />
                  <div>
                    <span>{points}</span>
                    {checkTranslationKey(translations[lang]?.POINTS, "POINTS")}
                  </div>
                  <img src="../img/icns/diamonds.svg" alt="" />
                </div>
                <p className="subtitle-modal">{subtitle}</p>
                {lives > 0 && (
                  <div className="extra">
                    <p>
                      {livesTextSplit[0]}
                      <img src="../img/icns/lives.svg" alt="" />
                      {livesTextSplit[1]}
                    </p>
                  </div>
                )}
                <p className="description">{nextLevelDescription}</p>

                <div className="btn-container">
                  <Button
                    buttonType="success"
                    type="submit"
                    value="Submit"
                    onClick={() => {
                      setModalOpen(false);
                      buttonClick();
                    }}
                  >
                    {buttonText}
                  </Button>
                </div>
              </motion.div>
            </>
          </Modal>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
