import { createApi } from "@reduxjs/toolkit/dist/query/react";
import baseQuery from "../../baseQuery";
import {
  AvatarsResponse,
  InitResponse,
  MiniTutorialsResponse,
} from "../../../../interfaces/general.types";
import { Translations } from "../../../../interfaces/translations.type";

export const generalApi = createApi({
  reducerPath: "generalApi",
  baseQuery,
  endpoints: (builder) => ({
    init: builder.query<InitResponse, void>({
      query: () => "init",
    }),
    getAvatars: builder.query<AvatarsResponse, void>({
      query: () => "repository/avatars",
    }),
    getTranslations: builder.query<Translations, void>({
      query: () => "static-texts",
    }),
    getMiniTutorials: builder.query<MiniTutorialsResponse, void>({
      query: () => "repository/mini-tutorials",
    }),
  }),
});

export const {
  useInitQuery,
  useGetAvatarsQuery,
  useGetTranslationsQuery,
  useGetMiniTutorialsQuery,
} = generalApi;
