import React, { useEffect, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGameEvents } from "../../../shared/data-access/store/game/hooks/use-game-events";
import { SocketResponseError } from "../../../shared/data-access/store/game/types/requests";
import { setMessage } from "../../../shared/data-access/store/message/messageSlice";
import { reset } from "../../../shared/data-access/store/game/gameSlices";
import { useAppDispatch } from "../../../hooks/hooks";
import { useLazyGetUserQuery } from "../../../auth/data-access/store/services/auth.service";

import Quiz from "./quiz/quiz";
import FindItem from "./find-item/find-item";

export type CommonParams = {
  playedBonusGame: {
    id: string;
  };
  userToken: string;
  uuid: string;
};

const GameplayBonus = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const { send: initGame, meta, data: game } = useGameEvents("init_bonus_game");
  const id = searchParams.get("id");
  const bonusGameType = game?.data?.bonus_game?.type;
  const navigate = useNavigate();
  const { token, uuid } = meta;
  const [getUser] = useLazyGetUserQuery();

  const commonParams = useMemo(() => {
    if (!id) {
      return false;
    }
    return {
      playedBonusGame: {
        id,
      },
      userToken: token,
      uuid,
    };
  }, [id, token, uuid]);

  useEffect(() => {
    if (!id) {
      navigate("/");
    } else {
      (async () => {
        try {
          if (commonParams) {
            await initGame(commonParams);
          }
        } catch (error: unknown) {
          handleError(error);

          setTimeout(() => {
            navigate("/");
            dispatch(reset());
          }, 3000);
        }
      })();
    }

    return () => {
      getUser();
    };
  }, []);

  const handleError = (error: unknown) => {
    const message =
      (error as Error).message ??
      (error as { error: Error }).error.message ??
      (error as { error: { error: string } }).error.error ??
      error ??
      "Unknown error";
    if ((error as SocketResponseError).error_header === 400) {
      dispatch(
        setMessage({
          content: message.includes("synchroniz")
            ? "Oops, this game has expired"
            : "Oops, something went wrong",
          type: "error",
        })
      );
    } else {
      dispatch(
        setMessage({
          content: "Oops, something went wrong",
          type: "error",
        })
      );
    }

    setTimeout(() => {
      dispatch(reset());
      navigate("/");
    }, 3000);
  };

  const renderGame = () => {
    if (!commonParams) {
      return null;
    }
    switch (bonusGameType) {
      case "fastest_finger":
        return <Quiz handleError={handleError} commonParams={commonParams} />;
      case "find_the_object":
        return (
          <FindItem handleError={handleError} commonParams={commonParams} />
        );
    }
  };

  return <>{renderGame()}</>;
};

export default GameplayBonus;
