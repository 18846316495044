import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectToken,
  selectUser,
} from "../../../../../auth/data-access/store/authSlice";
import { selectUserId } from "../../general/generalSlice";
import {
  resetEvent,
  selectEventResponse,
  selectGame,
  selectGameAndEventError,
  selectGameLoaded,
  selectGameState,
  updateEventResponse,
  updateError,
} from "../gameSlices";
import { SocketService } from "../services/socket.service";
import {
  SocketEventNames,
  SocketRequestParams,
  SocketResponse,
  SocketResponses,
  socketTypeAssert,
  socketTypeError,
} from "../types/requests";
export const useGameEvents = <
  Type extends Exclude<SocketEventNames, "init_game">
>(
  type: Type
) => {
  const socketService = useMemo(() => new SocketService(), []);
  const gameState = useSelector(selectGameState);
  const game = useSelector(selectGame);
  const data = useSelector(selectEventResponse(type));
  const error = useSelector(selectGameAndEventError(type));
  const gameLoaded = useSelector(selectGameLoaded);
  const user = useSelector(selectUser);
  const uuid = useSelector(selectUserId);
  const token = useSelector(selectToken);

  const dispatch = useDispatch();

  const resetState = useCallback(() => {
    dispatch(resetEvent(type));
  }, [dispatch, type]);

  const send = useCallback(
    async (params: SocketRequestParams<Type>) => {
      const listeners: Array<string> = [];
      const promise = new Promise<SocketResponse<Type>>((resolve, reject) => {
        listeners.push(
          socketService.on("response", (d: SocketResponses) => {
            if (socketTypeError(d) && d.call === type) {
              reject(d);
            } else if (socketTypeAssert<SocketResponse<Type>>(type, d)) {
              resolve(d);
            }
          })
        );

        listeners.push(
          socketService.on("error_code", (d: SocketResponses) => {
            if (socketTypeError(d)) {
              reject(d);
              if (d.call === type) {
                if (socketTypeAssert<SocketResponse<Type>>(type, d)) {
                  dispatch(updateEventResponse(d));
                }
              } else {
                dispatch(updateError({ ...d, call: type }));
              }
            }
          })
        );
      });

      setTimeout(() => {
        socketService.emit({
          call: type,
          params,
        });
      }, 100);

      return promise.finally(() =>
        listeners.forEach((l) => socketService.off(l))
      );
    },
    [socketService, type, dispatch]
  );
  return {
    data,
    game,
    send,
    error,
    gameState,
    gameLoaded,
    resetState,
    meta: {
      user,
      uuid,
      token,
    },
  };
};
