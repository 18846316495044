import { useEffect, useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Background } from "../../../shared/ui/background";
import { Badge } from "../../../shared/ui/badge";
import { Player } from "../../../shared/ui/player";
import { PrizeToWin } from "../../../shared/ui/prize-to-win";
import { UpstreamServiceBanner } from "../../../shared/ui/upstream-service-banner";

import "./opponent.scss";
import { useNavigate } from "react-router-dom";
import { useGameEvents } from "../../../shared/data-access/store/game/hooks/use-game-events";
import { setMessage } from "../../../shared/data-access/store/message/messageSlice";
import {
  countDownVariants,
  logoVariants,
  opponentVariants,
  thunderVariants,
} from "../../utils/animation-states";
import { secondsToMilliseconds } from "date-fns/esm";
import { checkTranslationKey } from "../../../utils/translation";
import {
  selectLanguage,
  selectTranslations,
} from "../../../shared/data-access/store/general/generalSlice";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import LoadingOpponent from "../../../shared/ui/loading-opponent/loading-opponent";
import useAmbientSound from "../../../utils/hooks/use-ambient-sound";

export function Opponent() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [countDown, setCountDown] = useState<boolean | number>(false);
  const { error, data, game, send, meta } = useGameEvents("get_opponent");
  const { user, token, uuid } = meta;
  const id = game?.id;
  const opponent = data?.data?.opponent;
  const opponentRef = useRef(opponent);
  const lang = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);
  useAmbientSound("opponent");

  useEffect(() => {
    const interval = setInterval(() => {
      if (countDown > 0 && typeof countDown === "number") {
        updateCountDown(countDown);
      } else if (countDown === 0) {
        // Countdown is finished so clear interval and redirect to game
        clearInterval(interval);
        navigate(`/ladder/`);
      }
    }, 1000);

    return () => clearInterval(interval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countDown]);

  useEffect(() => {
    opponentRef.current = opponent;
  }, [opponent]);

  useEffect(() => {
    if (id && uuid && token) {
      const timeout = setTimeout(() => {
        if (opponentRef.current?.id) {
          return;
        }

        send({
          playedGame: {
            id,
          },
          uuid: uuid,
          userToken: token,
        });
      }, secondsToMilliseconds(5));

      return () => {
        clearTimeout(timeout);
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, uuid, token]);

  useEffect(() => {
    if (opponent?.id) {
      // Start countdown when opponent is found
      setCountDown(3);
    }
  }, [opponent?.id]);

  useEffect(() => {
    if (error) {
      dispatch(
        setMessage({ content: "Oops, someting went wrong", type: "error" })
      );
    }
  }, [error, dispatch]);

  const updateCountDown = (timeLeft: number) => {
    setCountDown(timeLeft - 1);
  };

  return (
    <div id="opponent">
      <Background type="opponent">
        <div className="opponent-content">
          <div className="header-transparent">
            <motion.img
              src="/img/batteup-logo.png"
              alt={checkTranslationKey(
                translations[lang]?.BattleUp_Logo,
                "BattleUp Logo"
              )}
              className="logo"
              variants={logoVariants}
              initial="hidden"
              animate="visible"
            />
          </div>

          <div className="searching-content">
            <div className="searching-title">
              {checkTranslationKey(
                translations[lang]?.Searching_for_an_opponent,
                "Searching for an opponent..."
              )}
            </div>
            <div className="war-background">
              <div className="war-thunder">
                <motion.img
                  src="/img/prize/thunder.png"
                  alt=""
                  variants={thunderVariants}
                  initial="hidden"
                  animate={opponent ? "visible" : ""}
                />
              </div>
              <div className="player-content">
                <div className="player-photo">
                  <Player
                    bottom={
                      <Badge type="primary">
                        {user?.character?.level?.current}
                      </Badge>
                    }
                    avatar={user?.character?.avatar || ""}
                    name={user?.character?.nickname}
                    level={user?.character?.level?.current}
                  ></Player>
                </div>
                <div className="player-level"></div>
              </div>
              <div className="vs">
                <img src="/img/prize/vs.png" alt="" />
              </div>

              <AnimatePresence>
                {opponent ? (
                  <motion.div
                    className="player-content"
                    variants={opponentVariants}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                  >
                    <div className="player-photo">
                      <Player
                        bottom={<Badge type="primary">{opponent?.level}</Badge>}
                        avatar={opponent?.avatar}
                        name={opponent?.nickname}
                        level={opponent?.level}
                      ></Player>
                    </div>
                    <div className="player-level"></div>
                  </motion.div>
                ) : (
                  <LoadingOpponent />
                )}
              </AnimatePresence>
            </div>
            <AnimatePresence>
              {typeof countDown === "number" && (
                <motion.div
                  className="countdown"
                  variants={countDownVariants}
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                >
                  <div className="starting-title">
                    {checkTranslationKey(
                      translations[lang]?.Game_starts_in,
                      "Game starts in"
                    )}
                  </div>
                  <div className="number">{countDown}</div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          <div className="prizes">
            <div className="prizes-win">
              <PrizeToWin />
            </div>
            <div className="banner-position">
              <UpstreamServiceBanner />
            </div>
          </div>
        </div>
      </Background>
    </div>
  );
}

export default Opponent;
