import { Background } from "../background";
import "./loader.scss";

export function Loader() {
  return (
    <Background type="home">
      <div className="loader-wrapper">
        <div className="loader" />
      </div>
    </Background>
  );
}
