import { Middleware } from "@reduxjs/toolkit";
import { authApi } from "../../../../auth/data-access/store/services/auth.service";

export const notificationsMiddleware: Middleware =
  (store) => (next) => (action) => {
    if (action?.payload?.notifications) {
      store.dispatch(
        // @ts-ignore
        authApi.endpoints.getNotifications.initiate(undefined, {
          forceRefetch: true,
        })
      );
    }
    return next(action);
  };
