import { createSlice } from "@reduxjs/toolkit";
import { badgesApi } from "./services/badges.service";
import { AchievementsResponse } from "../../../shared/interfaces/general.types";
import { RootState } from "../../../shared/data-access/store/store";

type badgesState = {
  achievements: AchievementsResponse | [];
};

const initialState: badgesState = {
  achievements: [],
};

const badgesSlice = createSlice({
  name: "badges",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      badgesApi.endpoints.getAchievements.matchFulfilled,
      (state, { payload }) => {
        state.achievements = payload;
      }
    );
  },
});

const { reducer } = badgesSlice;

export const selectAchievements = (state: RootState) =>
  state.badges.achievements;

export default reducer;
