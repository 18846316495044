import { ComponentProps } from "react";
import { Button } from "../../button";

import "./happy-hour.scss";

export interface HappyHourProps extends ComponentProps<"div"> {
  title: string;
  description: string;
  buttonText: string;
  buttonClick: () => void;
}

export function HappyHour({
  title,
  description,
  buttonText,
  buttonClick,
}: HappyHourProps) {
  return (
    <div className="modal-content happy-hour-content">
      <div className="happy-hour-top-area">
        <div className="happy-hour-title">{title}</div>
        <div className="happy-hour-description">{description}</div>
      </div>
      <div className="bottom-area">
        <Button onClick={buttonClick} buttonType="success">
          {buttonText}
        </Button>
      </div>
    </div>
  );
}

export default HappyHour;
