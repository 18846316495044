import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import {
  forceNotificationAction,
  removeNotification,
  selectForceNotification,
  selectNotifications,
  selectNotificationsIsLoading,
  selectUser,
} from "../../auth/data-access/store/authSlice";
import { LevelUp } from "../../shared/ui/modal-notifications/level-up";
import { Notification } from "../../shared/interfaces/auth.types";
import {
  selectInit,
  selectLanguage,
  selectShowWelcomeBackModal,
} from "../../shared/data-access/store/general/generalSlice";
import { useConsumeNotificationsMutation } from "../../auth/data-access/store/services/auth.service";
import { BadgeModal } from "../../badges/ui/achievement-modal";
import { Modal } from "../../shared/ui/modal";
import PlayBonusGame from "../../shared/ui/modal-notifications/play-bonus-game/play-bonus-game";
import { useLocation, useNavigate } from "react-router-dom";
import SoundManager from "../../utils/managers/sound-manager";
import { HappyHour } from "../../shared/ui/modal-notifications/happy-hour";
import { BorderUnlocked } from "../../shared/ui/modal-notifications/border-unlocked";
import TimeLimitedBoost from "../../shared/ui/modal-notifications/time-limited-boost/time-limited-boost";

export const Notifications = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const notifications = useAppSelector(selectNotifications);
  const [showNotification, setShowNotification] = useState(false);
  const lang = useAppSelector(selectLanguage);
  const [consumeNotifications] = useConsumeNotificationsMutation();
  const { pathname } = useLocation();
  const forceNotification = useAppSelector(selectForceNotification);
  const notificationsIsLoading = useAppSelector(selectNotificationsIsLoading);
  const showWelcomeBackModal = useAppSelector(selectShowWelcomeBackModal);
  const init = useAppSelector(selectInit);
  const user = useAppSelector(selectUser);

  const priority = {
    achievement: 6,
    levelUp: 5,
    borderAvatar: 4,
    bonusGame: 3,
    happyHour: 2,
    timeLimitedBoost: 1,
  };

  // Initialize an empty array for filtered notifications
  const filteredNotifications: Array<Notification> = [];

  // Loop through each notification and apply the necessary filters
  // Do not show the promo video notification
  // Do not show the happy hour notification if buying credits is disabled
  notifications?.forEach((notification) => {
    if (
      !(
        notification.type === "achievement" &&
        notification.text?.title[lang] === "Promo video"
      ) &&
      !(init?.features.disableBuyCredits && notification.type === "happyHour")
    ) {
      filteredNotifications.push(notification);
    }
  });

  // Sort notifications by priority
  const sortedNotifications = filteredNotifications?.sort(
    (a, b) => priority[b.type] - priority[a.type]
  );

  const handleConsumeNotification = (nodificationId: string) => {
    setShowNotification(false);
    if (sortedNotifications && sortedNotifications.length > 0) {
      consumeNotifications({ id: nodificationId });
      dispatch(removeNotification({ id: nodificationId }));
    }
    if (forceNotification.show) {
      dispatch(forceNotificationAction({ show: false, id: null }));
    }
  };

  useEffect(() => {
    if (!showWelcomeBackModal) {
      let timeout: NodeJS.Timeout;
      if (
        (sortedNotifications &&
          sortedNotifications.length &&
          !checkIfInGame(pathname)) ||
        forceNotification.show
      ) {
        timeout = setTimeout(() => {
          if (!notificationsIsLoading) {
            setShowNotification(true);
          }
        }, 400);
      }
      return () => {
        if (timeout) {
          clearTimeout(timeout);
        }
      };
    }
  }, [
    sortedNotifications,
    consumeNotifications,
    pathname,
    forceNotification,
    notificationsIsLoading,
    showWelcomeBackModal,
  ]);

  const checkIfInGame = (path: string) => {
    return (
      path.includes("opponent") ||
      path.includes("ladder") ||
      path.includes("gameplay") ||
      path.includes("category") ||
      path.includes("results") ||
      path.includes("onboarding")
    );
  };

  const renderNotificationByType = (notification: Notification) => {
    console.log({ showNotification });
    switch (notification.type) {
      case "levelUp":
        return (
          <LevelUp
            modalOpen={
              forceNotification.show
                ? forceNotification.id === notification.id
                : showNotification
            }
            setModalOpen={setShowNotification}
            buttonClick={() => {
              handleConsumeNotification(notification.id);
              SoundManager.ambientSounds.home.volume = 0.6;
              SoundManager.ambientSounds.home.play();
            }}
            level={notification.text?.new_level}
            title={notification.text?.level_up[lang]}
            subtitle={notification.text?.awesome[lang]}
            lives={notification.text?.amount_lives_awarded}
            livesText={notification.text?.award_lives[lang]}
            nextLevelDescription={notification.text?.next_threshold[lang]}
            points={notification.text?.current_diamonds}
            buttonText={notification.button_text[lang]}
          />
        );
      case "bonusGame": {
        return (
          <PlayBonusGame
            modalOpen={
              forceNotification.show
                ? forceNotification.id === notification.id
                : showNotification
            }
            setModalOpen={setShowNotification}
            title={notification.text?.description[lang]}
            subtitle={notification.text?.title[lang]}
            description={notification.text?.hint[lang]}
            buttonText={notification.button_text[lang]}
            buttonClick={() => {
              handleConsumeNotification(notification.id);
              navigate(`/gameplay/bonus?id=${notification.target_id}`);
            }}
          ></PlayBonusGame>
        );
      }
      case "achievement": {
        return (
          <Modal
            show={
              forceNotification.show
                ? forceNotification.id === notification.id
                : showNotification
            }
            setShow={setShowNotification}
            hideCloseButton
            disableClickOutside={true}
          >
            <BadgeModal
              badge={notification.image}
              title={notification.text?.title[lang]}
              buttonText={notification.button_text[lang]}
              buttonClick={() => {
                handleConsumeNotification(notification.id);
              }}
            >
              {notification.text?.description[lang]}
            </BadgeModal>
          </Modal>
        );
      }
      case "happyHour":
        return (
          <Modal
            show={
              forceNotification.show
                ? forceNotification.id === notification.id
                : showNotification
            }
            setShow={setShowNotification}
            handleClose={() => handleConsumeNotification(notification.id)}
            disableClickOutside={true}
          >
            <HappyHour
              buttonClick={() => {
                navigate("/store", { state: "coins" });
                handleConsumeNotification(notification.id);
              }}
              title={notification.title[lang]}
              description={notification.text[lang]}
              buttonText={notification.button_text[lang]}
            />
          </Modal>
        );
      case "borderAvatar":
        return (
          <BorderUnlocked
            modalOpen={
              forceNotification.show
                ? forceNotification.id === notification.id
                : showNotification
            }
            setModalOpen={setShowNotification}
            buttonClick={() => handleConsumeNotification(notification.id)}
            level={notification.text?.new_level}
            title={notification.text?.title[lang]}
            subtitle={notification.text?.description[lang]}
            buttonText={notification.button_text[lang]}
            avatar={user?.character?.avatar || ""}
          />
        );
      case "timeLimitedBoost":
        return (
          <TimeLimitedBoost
            modalOpen={
              forceNotification.show
                ? forceNotification.id === notification.id
                : showNotification
            }
            setModalOpen={setShowNotification}
            title={notification.title[lang]}
            description={notification.text[lang]}
            buttonText={notification.button_text[lang]}
            buttonClick={() => {
              handleConsumeNotification(notification.id);
              navigate(`/gameplay/bonus?id=${notification.target_id}`);
            }}
          ></TimeLimitedBoost>
        );
    }
  };

  return (
    <>
      {sortedNotifications && sortedNotifications.length > 0
        ? renderNotificationByType(
            forceNotification.show
              ? (sortedNotifications.find(
                  (notification) => notification.id === forceNotification.id
                ) as Notification)
              : sortedNotifications[0]
          )
        : null}
    </>
  );
};

export default Notifications;
