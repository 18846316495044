import React from "react";
import reactStringReplace from "react-string-replace";
import "./text-underscore.scss";

export interface TextUnderscoreProps {
  children: string;
}

export function TextUnderscore({ children: text }: TextUnderscoreProps) {
  return (
    <React.Fragment>
      {reactStringReplace(text, /(__+)/gm, (match: string, index: number) => {
        return (
          <span key={index} className="underscore-style">
            {match}
          </span>
        );
      })}
    </React.Fragment>
  );
}

export default TextUnderscore;
