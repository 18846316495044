import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

import "./store-offers.scss";
import "swiper/css";
import "swiper/css/pagination";
import { useGetBannersQuery } from "../../data-access/store/services/store.service";
import { BannersResponse } from "../../../shared/interfaces/general.types";
import { AnimatePresence, motion, Variants } from "framer-motion";

const offersContainerVariants: Variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.3,
    },
  },
};

export function OffersPage() {
  const { data: bannersData } = useGetBannersQuery();

  const renderBanners = (banners: BannersResponse["items"]) => {
    return banners?.map((banner) => {
      return (
        <SwiperSlide key={banner.id} className="shop-slide">
          <a href={banner.url} target="_blank">
            <img src={banner.image} alt="Banner" />
          </a>
          {/*<div className="shop-title">Lorem ipsum</div>*/}
          {/*<div className="shop-ad">*/}
          {/*  <div className="text-ad">*/}
          {/*    Benefits from the <mark>most exclusive club!</mark> Join us for*/}
          {/*    shopping and you could win R25.000 cash!*/}
          {/*  </div>*/}
          {/*  <div className="sign-up-btn">*/}
          {/*    <button className="button-red-sign-up">Sign up now</button>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </SwiperSlide>
      );
    });
  };

  return (
    <AnimatePresence>
      {bannersData && (
        <motion.div
          variants={offersContainerVariants}
          initial="hidden"
          animate="visible"
        >
          <Swiper
            slidesPerView={1}
            centeredSlides={true}
            spaceBetween={10}
            pagination={{ clickable: true }}
            modules={[Pagination]}
            className="shop-swiper"
          >
            {bannersData && bannersData.items
              ? renderBanners(bannersData.items)
              : null}
          </Swiper>
          <div className="shop-bullets" />
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default OffersPage;
