import { ComponentProps } from "react";
import "./profile-settings-button.scss";

export interface ProfileSettingsProps extends ComponentProps<"button"> {
  icon:
    | "pencil-fill"
    | "global-line"
    | "mail-forbid-line"
    | "volume-up-fill"
    | "volume-mute-fill";
}

export function ProfileSettingsButton({
  children,
  icon,
  ...props
}: ProfileSettingsProps) {
  return (
    <button {...props} className="profile-settings-button">
      <span className="slot-left">
        <img src={`../img/icns/${icon}.svg`} />
      </span>
      <span className="text-button">{children}</span>
      <span className="slot-right"></span>
    </button>
  );
}

export default ProfileSettingsButton;
