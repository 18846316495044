import React, { ComponentProps, useEffect } from "react";
import { Button } from "../../button";
import "./time-limited-boost.scss";
import { AnimatePresence, motion, Variants } from "framer-motion";
import { Modal } from "../../modal";
import SoundManager from "../../../../utils/managers/sound-manager";
import { fadeOutBgAudio } from "../../../../utils/fade-audio";

const timeLimitedBoostContainerVariants: Variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.3,
    },
  },
};

const contentVariants: Variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.3,
      delay: 1.1,
    },
  },
};

const bgVariants: Variants = {
  hidden: {
    opacity: 0,
    scale: 0,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.3,
      delay: 0.3,
      scale: {
        type: "spring",
        bounce: 0.7,
      },
    },
  },
};

export interface TimeLimitedBoostProps extends ComponentProps<"div"> {
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  description: string;
  buttonText: string;
  buttonClick: () => void;
}

const TimeLimitedBoost = ({
  modalOpen,
  setModalOpen,
  title,
  description,
  buttonText,
  buttonClick,
}: TimeLimitedBoostProps) => {
  useEffect(() => {
    setTimeout(() => {
      if (modalOpen) {
        fadeOutBgAudio(
          SoundManager.ambientSounds.home,
          SoundManager.vfxSounds.wow
        );
      } else {
        SoundManager.vfxSounds.wow.pause();
      }
    }, 100);
  }, [modalOpen]);

  return (
    <AnimatePresence>
      {modalOpen && (
        <motion.div
          id="modal-time-limited-boost"
          variants={timeLimitedBoostContainerVariants}
          initial={"hidden"}
          animate={"visible"}
          exit={"hidden"}
        >
          <Modal
            show={modalOpen}
            setShow={setModalOpen}
            hideCloseButton
            defaultAnimations={false}
            disableClickOutside
          >
            <motion.div
              className="time-limited-boost-container"
              variants={bgVariants}
              initial={"hidden"}
              animate={modalOpen && "visible"}
            >
              <div className="time-limited-boost-header"></div>
              <div className="image-wrapper">
                <img
                  src={"../img/time-limited-boost/boost.png"}
                  alt=""
                  className="header-image"
                />
              </div>
              <motion.div
                className="time-limited-boost-content"
                variants={contentVariants}
                initial={"hidden"}
                animate={"visible"}
              >
                <div className="time-limited-boost-title">{title}</div>
                <div className="time-limited-boost-subtitle">
                  <p>
                    Get 2x <img src="../img/icns/diamond-flipped.svg" alt="" />{" "}
                    now!
                  </p>
                </div>
                <div
                  className="time-limited-boost-text"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
                <div className="share-area">
                  <Button buttonType="success" onClick={buttonClick}>
                    {buttonText}
                  </Button>
                </div>
              </motion.div>
            </motion.div>
          </Modal>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default TimeLimitedBoost;
