// If the utag call could possibly happen before utag has loaded (e.g. page load)
// This will make the call as soon as utag has loaded (it still drops it if utag doesn't load in a certain amount of time)

const windowWithUtag = window as typeof window & {
  utag: {
    link: (args: { [k: string]: string }) => void;
    view: (args: { [k: string]: string }) => void;
  };
};
export const withUtag = async () => {
  if (windowWithUtag.utag) {
    return windowWithUtag.utag;
  }
  let i = 0;
  while (i < 50) {
    await new Promise((resolve) => setTimeout(resolve, 200));
    if (windowWithUtag.utag) {
      return windowWithUtag.utag;
    }
    i = i + 1;
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  return { link: () => {}, view: () => {} };
};
