import { useEffect } from "react";
import SoundManager from "../managers/sound-manager";
import useLocals from "./use-locals";
import { checkiOS } from "../checkIOS";
import { fadeOutBgAudio } from "../fade-audio";

type UseSoundParams = keyof typeof SoundManager.ambientSounds;

const useAmbientSound = (props: UseSoundParams, play = true) => {
  const soundName = props;
  const { getValue } = useLocals();

  useEffect(() => {
    const sound = SoundManager.ambientSounds[soundName];
    sound.loop = true;

    // if sounds are disabled mute all audios
    for (const [, value] of Object.entries(SoundManager.vfxSounds)) {
      value.muted = !!getValue("soundsDisabled");
    }

    // autoplay ambient music if sounds are NOT disabled
    if (!getValue("soundsDisabled")) {
      if (play) {
        sound.play();

        if (!checkiOS()) {
          if (sound.currentTime < 1) {
            sound.muted = true;
            sound.volume = 0;
          }

          sound.muted = false;
          const fadeAudio = setInterval(function () {
            if (sound.volume >= 0.8) {
              sound.volume = 1;
              clearInterval(fadeAudio);
            } else {
              sound.volume += 0.2;
            }
          }, 100);
        } else {
          sound.volume = 1;
          sound.muted = false;
        }

        // sound.play();
        // SoundManager.ambientSounds.home.volume = 0.6;
        // SoundManager.ambientSounds.opponent.volume = 0.6;
      }
    }

    return () => {
      // sound.pause();
      if (soundName !== "home" && sound.currentTime) {
        fadeOutBgAudio(sound);
      } else if (soundName === "home") {
        // sound.pause();
      }
      // sound.currentTime = 0;
    };
  }, [play]);
};

export default useAmbientSound;
