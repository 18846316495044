import { ComponentProps } from "react";
import "./title.scss";
import { Link } from "react-router-dom";
import { motion, Variants } from "framer-motion";
import { useAppSelector } from "../../../hooks/hooks";
import {
  selectLanguage,
  selectTranslations,
} from "../../data-access/store/general/generalSlice";
import { checkTranslationKey } from "../../../utils/translation";

export interface TitleProps extends ComponentProps<"div"> {
  children: string;
  backURL?: string;
  small?: boolean;
}

export function Title({ children, backURL, small }: TitleProps) {
  const lang = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);

  const titleVariants: Variants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.3,
        ease: "easeOut",
        delay: 0.3,
      },
    },
  };

  return (
    <motion.div
      className="title-nav"
      variants={titleVariants}
      initial="hidden"
      animate="visible"
    >
      <div className="back-button">
        {backURL ? (
          <Link to={backURL}>
            <img src="/img/icns/arrow-back.svg" alt="" />
            <span>{checkTranslationKey(translations[lang]?.Back, "Back")}</span>
          </Link>
        ) : null}
      </div>

      {/* <div className="back-button">
        {backURL ? `URL is: ${backURL}` : null}
        <a href="">
          <img src="../img/icns/arrow-back.svg" alt="" />
          <span>Back</span>
        </a>
      </div> */}

      {/* <div>{backURL ? `URL is: ${backURL}` : null}</div> */}
      <div className={`title ${small ? "small" : ""}`}>{children}</div>
      <div></div>
    </motion.div>
  );
}

export default Title;
