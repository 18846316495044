import { Navigate, Route, Routes } from "react-router-dom";
import StoreView from "../store-view/store-view";

export function StoreShell() {
  return (
    <Routes>
      <Route path="/" element={<StoreView />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
}

export default StoreShell;
