import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../shared/data-access/store/store";
import { authApi } from "./services/auth.service";
import {
  NotificationsResponse,
  User,
} from "../../../shared/interfaces/auth.types";

const accessToken = localStorage.getItem("accessToken");

type AuthState = {
  user: User | null;
  isFirstSignIn: boolean;
  isAuthenticated: boolean;
  token: string;
  notifications: NotificationsResponse["items"] | null;
  notificationsDelayTime: number;
  forceNotification: { show: boolean; id: string | null };
  notificationsIsLoading: boolean;
};

const initialState: AuthState = {
  user: null,
  isFirstSignIn: false,
  isAuthenticated: false,
  token: accessToken || "",
  notifications: null,
  notificationsDelayTime: 0,
  forceNotification: { show: false, id: null },
  notificationsIsLoading: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = initialState.user;
      state.isAuthenticated = false;
      state.token = "";
      state.notifications = initialState.notifications;
      state.notificationsDelayTime = initialState.notificationsDelayTime;
      state.forceNotification = initialState.forceNotification;
      state.isFirstSignIn = false;
    },
    tokenReceived: (state, action) => {
      state.token = action.payload;
    },
    removeNotification: (state, action) => {
      state.notifications =
        state.notifications?.filter(
          (notification) => notification.id !== action.payload.id
        ) || null;
    },
    delayNotifications: (state, action) => {
      state.notificationsDelayTime = action.payload;
    },
    forceNotificationAction: (
      state,
      action: PayloadAction<AuthState["forceNotification"]>
    ) => {
      state.forceNotification = action.payload;
    },
    updateIsFirstSignIn: (
      state,
      action: PayloadAction<AuthState["isFirstSignIn"]>
    ) => {
      state.isFirstSignIn = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        authApi.endpoints.login.matchFulfilled,
        (state, { payload }) => {
          if (payload.apikey) {
            state.isAuthenticated = true;
            state.token = payload.apikey;
            state.isFirstSignIn = payload.isFirstSignIn;
          }
        }
      )
      .addMatcher(
        authApi.endpoints.loginWithToken.matchFulfilled,
        (state, { payload }) => {
          if (payload.apikey) {
            state.isAuthenticated = true;
            state.token = payload.apikey;
          }
        }
      )
      .addMatcher(
        authApi.endpoints.loginWithMsisdn.matchFulfilled,
        (state, { payload }) => {
          if (payload.apikey) {
            state.isAuthenticated = true;
            state.token = payload.apikey;
            state.isFirstSignIn = payload.isFirstSignIn;
          }
        }
      )
      .addMatcher(
        authApi.endpoints.getUser.matchFulfilled,
        (state, { payload }) => {
          state.user = payload;
          state.isAuthenticated = true;
        }
      )
      .addMatcher(authApi.endpoints.getUser.matchRejected, (state) => {
        state.user = initialState.user;
        state.isAuthenticated = false;
      })
      .addMatcher(
        authApi.endpoints.putUser.matchFulfilled,
        (state, { payload }) => {
          state.user = payload;
        }
      )
      .addMatcher(
        authApi.endpoints.getNotifications.matchFulfilled,
        (state, { payload }) => {
          state.notificationsIsLoading = false;
          state.notifications = payload.items;
        }
      )
      .addMatcher(authApi.endpoints.getNotifications.matchRejected, (state) => {
        state.notificationsIsLoading = false;
      })
      .addMatcher(authApi.endpoints.getNotifications.matchPending, (state) => {
        state.notificationsIsLoading = true;
      })
      .addMatcher(
        authApi.endpoints.subscribeDirect.matchFulfilled,
        (state, { payload }) => {
          if (state.user && payload) {
            state.token = payload.apikey;
          }
        }
      );
  },
});

const { reducer, actions } = authSlice;

export const {
  logout,
  tokenReceived,
  removeNotification,
  delayNotifications,
  forceNotificationAction,
  updateIsFirstSignIn,
} = actions;

export const selectAuthState = (state: RootState): AuthState => state.auth;

export const selectUser = createSelector(
  selectAuthState,
  (state: AuthState) => state.user
);

export const selectIsAuthenticated = createSelector(
  selectAuthState,
  (state: AuthState) => state.isAuthenticated
);

export const selectToken = createSelector(
  selectAuthState,
  (state: AuthState) => state.token
);

export const selectNotifications = (state: RootState) =>
  state.auth.notifications;
export const selectNotificationsDelayTime = (state: RootState) =>
  state.auth.notificationsDelayTime;
export const selectForceNotification = (state: RootState) =>
  state.auth.forceNotification;
export const selectNotificationsIsLoading = (state: RootState) =>
  state.auth.notificationsIsLoading;
export const selectIsFirstSignIn = (state: RootState) =>
  state.auth.isFirstSignIn;

export default reducer;
