import { ComponentProps } from "react";
import "./tab-sliding.scss";
import "../button/button.scss";
import { useAppSelector } from "../../../hooks/hooks";
import {
  selectInit,
  selectLanguage,
  selectTranslations,
} from "../../data-access/store/general/generalSlice";
import { checkTranslationKey } from "../../../utils/translation";

export type Tab = "coins" | "offers";

export interface TabProps extends ComponentProps<"div"> {
  tabChange: (tab: Tab) => void;
  tab: Tab;
}

export function TabSliding({ tabChange, tab, ...props }: TabProps) {
  const init = useAppSelector(selectInit);
  const buyingDisabled = init?.features?.disableBuyCredits;
  const lang = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);

  return (
    <div {...props} className="tabs-container">
      <div className="tabs">
        {/* <input type="radio" id="radio-1" name="tabs" checked /> */}
        <label
          className={`tab ${tab === "offers" ? "active" : ""}`}
          htmlFor="radio-1"
          onClick={() => tabChange("offers")}
        >
          {checkTranslationKey(translations[lang]?.Offers, "Offers")}
        </label>
        {/* <input type="radio" id="radio-2" name="tabs" /> */}
        {!buyingDisabled && (
          <label
            className={`tab ${tab === "coins" ? "active" : ""}`}
            htmlFor="radio-2"
            onClick={() => tabChange("coins")}
          >
            {checkTranslationKey(translations[lang]?.Coins, "Coins")}
          </label>
        )}
        <span
          className={`glider glider-success selected-${tab}`}
          style={{
            width: buyingDisabled ? "100%" : "50%",
            maxWidth: buyingDisabled ? "100%" : "318px",
          }}
        >
          <div className="button-inner-overlay"></div>
        </span>
      </div>
    </div>
  );
}

export default TabSliding;
