import { ComponentProps } from "react";
import { Rewards } from "../../interfaces/rewards.type";
import "./points-area.scss";
import { motion, Variants } from "framer-motion";

export interface PointsAreaProps extends ComponentProps<"div"> {
  icon: Rewards;
  type?: "primary" | "secondary";
  count: number | "∞" | undefined;
  label: string;
  itemCount?: number;
  total?: number;
  action?: () => void;
}

export function PointsArea({
  icon,
  type = "secondary",
  count = 0,
  label,
  itemCount = 0,
  total = 0,
  action,
  ...props
}: PointsAreaProps) {
  const iconVariants: Variants = {
    pulse: (custom: number) => ({
      scale: [1, 1.2, 1],
      transition: {
        repeatDelay: total - 1,
        duration: 1,
        repeat: Infinity,
        ease: "easeOut",
        delay: 1 * (1 + custom),
      },
    }),
  };

  return (
    <div
      {...props}
      className={`area area-${type} ${icon} ${props.className ?? ""}`}
      onClick={action}
    >
      <div className="area-counter">
        <div className="counter">{count}</div>
        <div className="label">{label}</div>
      </div>
      <motion.div
        className="icon-position"
        variants={iconVariants}
        animate="pulse"
        custom={itemCount}
      >
        <img src={`../img/icns/${icon}.png`} className={`icon-${type}`} />
      </motion.div>
    </div>
  );
}

export default PointsArea;
