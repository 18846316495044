import { useEffect, useMemo, useRef } from "react";
import SoundManager from "../managers/sound-manager";

// adaptation from : https://curtisrobinson.medium.com/how-to-auto-play-audio-in-safari-with-javascript-21d50b0a2765

export const useAudioUnlocker = () => {
  const audioList = useMemo(
    () => [
      ...Object.keys(SoundManager.ambientSounds).map(
        (k) =>
          SoundManager.ambientSounds[
            k as keyof typeof SoundManager.ambientSounds
          ]
      ),
      ...Object.keys(SoundManager.vfxSounds).map(
        (k) => SoundManager.vfxSounds[k as keyof typeof SoundManager.vfxSounds]
      ),
    ],
    []
  );

  const audioRef = useRef(audioList);

  useEffect(() => {
    const unlock = () => {
      for (const audio of audioRef.current) {
        audio.play();
        audio.pause();
        audio.currentTime = 0;
      }

      audioRef.current = [];
    };
    document.body.addEventListener("touchstart", unlock);
    return () => {
      document.body.removeEventListener("touchstart", unlock);
    };
  }, []);

  let audioPlaying: HTMLAudioElement | null = null;

  const handleVisibilityChange = (hidden: boolean) => {
    if (hidden) {
      for (const audio of audioList) {
        if (!audio.paused) {
          audioPlaying = audio;
        }
      }
      if (audioPlaying) {
        audioPlaying.pause();
      }
    } else {
      if (audioPlaying) {
        audioPlaying.play();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "hidden") {
        handleVisibilityChange(true);
      } else {
        handleVisibilityChange(false);
      }
    });
  }, []);
};
