import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { withUtag } from "../tracking";
import packageInfo from "../../../package.json";
import {
  pageCategoryByPath,
  pageTitleFromPath,
  siteSectionByPath,
} from "../pageDataFromPath";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import {
  selectRouteHistory,
  selectUserId,
  updateRouteHistory,
} from "../../shared/data-access/store/general/generalSlice";
import { selectUser } from "../../auth/data-access/store/authSlice";
import debounce from "lodash.debounce";

const usePageTracking = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const routeHistory = useAppSelector(selectRouteHistory);
  const user = useAppSelector(selectUser);
  const uuid = useAppSelector(selectUserId);
  const [debouncedLocation, setDebouncedLocation] = useState(location.pathname);

  useEffect(() => {
    const handler = debounce(() => {
      setDebouncedLocation(location.pathname);
    }, 300); // Adjust delay as needed

    handler();
    return () => handler.cancel();
  }, [location]);

  useEffect(() => {
    console.log("window.location", debouncedLocation);
    dispatch(updateRouteHistory(location.pathname));
    // ReactGA.send({
    //   hitType: "pageview",
    //   page: window.location.pathname + window.location.search,
    // });
    // Usage: Use the resolved utag object from the Promise
    withUtag().then((utag) => {
      utag.view({
        server_name: window.location.hostname,
        site_section: siteSectionByPath(location.pathname),
        platform_name: window.navigator.platform,
        site_version: packageInfo.version,
        page_name: pageTitleFromPath(location.pathname),
        page_type: siteSectionByPath(location.pathname),
        page_title: pageTitleFromPath(location.pathname),
        page_url: document.URL,
        page_path: location.pathname,
        page_path_query: location.search,
        page_category: pageCategoryByPath(location.pathname),
        page_channel: "page",
        previous_page_name: routeHistory[routeHistory.length - 2],
        visitor_login_status: user ? "logged in" : "logged out",
        ...(user ? { visitor_id_asset_active: user.character.id } : {}),
        uuid: uuid,
      });

      utag.link({
        visitor_login_status: user ? "logged in" : "logged out",
        ...(user ? { visitor_id_asset_active: user.character.id } : {}),
        uuid: uuid,
      });
    });
  }, [debouncedLocation]);
};

export default usePageTracking;
