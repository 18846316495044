import { Button } from "../../../shared/ui/button";
import { Title } from "../../../shared/ui/title";
import { Auth } from "../auth/auth";
import cx from "classnames";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";

import { useGenerateMutation } from "../../data-access/store/services/auth.service";
import { useEffect, useState } from "react";
import { setMessage } from "../../../shared/data-access/store/message/messageSlice";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import {
  selectInit,
  selectLanguage,
  selectTranslations,
} from "../../../shared/data-access/store/general/generalSlice";
import { useNavigate } from "react-router-dom";
import { checkTranslationKey } from "../../../utils/translation";
import { UpstreamServiceBanner } from "../../../shared/ui/upstream-service-banner";

import "./subscribe.scss";

export function Subscribe() {
  const [msisdn, setMsisdn] = useState<string>("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [
    generate,
    {
      isLoading: generateIsLoading,
      isSuccess: generateIsSuccess,
      error: generateError,
    },
  ] = useGenerateMutation();
  const init = useAppSelector(selectInit);
  const lang = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);

  useEffect(() => {
    if (generateError) {
      const errorMsg =
        (generateError as { data?: Error })?.data?.message ||
        checkTranslationKey(translations[lang]?.Error, "Error!");
      if (errorMsg) {
        dispatch(setMessage({ content: errorMsg, type: "error" }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generateError]);

  useEffect(() => {
    if (generateIsSuccess) {
      navigate("/auth/pin", { state: msisdn });
    }
  }, [generateIsSuccess]);

  const prefix = init?.users?.msisdn?.prefix || "";
  const phoneMaxLength = init?.users?.msisdn?.maxLength || 9;
  const phoneMinLength = init?.users?.msisdn?.minLength || 9;

  const SubscribeSchema = Yup.object().shape({
    phone_subscribe: Yup.string()
      .matches(
        /^[0-9]+$/,
        checkTranslationKey(
          translations[lang]?.The_phone_number_must_be_only_digits,
          "The phone number must be only digits"
        )
      )
      .min(
        phoneMinLength,
        checkTranslationKey(
          translations[lang]?.The_phone_number_is_too_short,
          "The phone number is too short."
        )
      )
      .max(
        phoneMaxLength,
        checkTranslationKey(
          translations[lang]?.The_phone_number_is_too_long,
          "The phone number is too long."
        )
      )
      .required(
        checkTranslationKey(
          translations[lang]?.The_phone_number_is_required,
          "The phone number is required."
        )
      ),
  });

  return (
    <div className="auth-page subscribe-page">
      <Auth
        className="auth-shell-content"
        header={
          <Title>
            {checkTranslationKey(translations[lang]?.Subscribe, "Subscribe")}
          </Title>
        }
      >
        <div className="form-title form-title-login">
          {checkTranslationKey(
            translations[lang]?.Create_your_account_and_subscribe,
            "Create your account and subscribe"
          )}
        </div>
        <div className="form-container">
          <Formik
            initialValues={{ phone_subscribe: "" }}
            validationSchema={SubscribeSchema}
            onSubmit={(values) => {
              setMsisdn(prefix + values.phone_subscribe);
              generate({ msisdn: prefix + values.phone_subscribe });
            }}
          >
            {({ errors, touched }) => (
              <Form>
                <div
                  className={cx("form-input-row", {
                    danger: errors.phone_subscribe && touched.phone_subscribe,
                    success: !errors.phone_subscribe,
                  })}
                >
                  <label className="label" htmlFor="phone_subscribe">
                    Telephone number for your account
                  </label>
                  <div className="prefix-input">
                    <span className="prefix">+{prefix}</span>
                    <Field
                      type="tel"
                      name="phone_subscribe"
                      className="half-input"
                      placeholder={checkTranslationKey(
                        translations[lang]?.Your_phone_number,
                        "Your phone number"
                      )}
                      autoComplete="off"
                      maxLength={phoneMaxLength}
                      required
                    />
                  </div>
                  <ErrorMessage name="phone_subscribe">
                    {(msg) => (
                      <div className="error-msg">
                        <img
                          src="/img/icns/alert-fill.svg"
                          alt="alert"
                          className="color-svg"
                        />
                        {msg}
                      </div>
                    )}
                  </ErrorMessage>
                </div>

                <div>
                  <div className="submit">
                    <Button
                      buttonType="success"
                      type="submit"
                      value="Submit"
                      disabled={generateIsLoading}
                    >
                      {generateIsLoading
                        ? checkTranslationKey(
                            translations[lang]?.Submitting,
                            "Submitting..."
                          )
                        : checkTranslationKey(translations[lang]?.Next, "Next")}
                    </Button>
                  </div>
                  <p className="cost">Cost: R5/day</p>
                  <p className="cost-free">First Day Free</p>
                  <div className="terms-area">
                    {checkTranslationKey(
                      translations[lang]?.You_will_soon_receive_a_PIN,
                      "You will soon receive a password. By entering the password you confirm subscription and agree to the "
                    )}{" "}
                    <a href={init?.content?.terms[lang]} target="_blank">
                      {checkTranslationKey(
                        translations[lang]?.Terms_and_Conditions,
                        "Terms and Conditions"
                      )}
                    </a>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Auth>
      <div className="stream-service">
        <UpstreamServiceBanner />
      </div>
    </div>
  );
}

export default Subscribe;
