import React from "react";
import { TooltipRenderProps } from "react-joyride";
import { Button } from "../button";
import { useAppSelector } from "../../../hooks/hooks";
import {
  selectLanguage,
  selectTranslations,
} from "../../data-access/store/general/generalSlice";
import { checkTranslationKey } from "../../../utils/translation";
import ButtonClose from "../button-close/button-close";

import "./modal-steps.scss";

type ModalStepsProps = TooltipRenderProps & {
  totalSteps?: number;
  handleClose?: () => void;
};

const ModalSteps = ({
  continuous,
  index,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
  totalSteps,
  handleClose,
}: ModalStepsProps) => {
  const translations = useAppSelector(selectTranslations);
  const lang = useAppSelector(selectLanguage);

  return (
    <div {...tooltipProps} className="modal-steps-container">
      <div className="close">
        <ButtonClose
          onClick={(event) =>
            handleClose ? handleClose() : closeProps.onClick(event)
          }
        />
      </div>
      {step.title && <h5 className="title">{step.title}</h5>}
      <div className="content">{step.content}</div>
      <div className="footer">
        {continuous && totalSteps && totalSteps > 1 && (
          <p className="step-summary">
            {checkTranslationKey(translations[lang]?.x_of_y, "{{x}} of {{y}}")
              .replace("{{x}}", `${index + 1}`)
              .replace("{{y}}", `${totalSteps}`)}
          </p>
        )}
        <div className="modal-steps-buttons">
          {index > 0 && (
            <Button
              {...backProps}
              buttonType="white"
              className="modal-steps-btn"
            >
              {checkTranslationKey(translations[lang]?.Back, "Back")}
            </Button>
          )}
          {continuous &&
            (totalSteps && index === totalSteps - 1 ? (
              <Button
                {...primaryProps}
                buttonType="success"
                className="modal-steps-btn"
              >
                {checkTranslationKey(translations[lang]?.done, "Done")}
              </Button>
            ) : (
              <Button
                {...primaryProps}
                buttonType="success"
                className="modal-steps-btn"
              >
                {checkTranslationKey(translations[lang]?.Next, "Next")}
              </Button>
            ))}
          {!continuous && (
            <Button {...closeProps} className="modal-steps-btn">
              {checkTranslationKey(translations[lang]?.done, "Done")}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalSteps;
